import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core";
import generateMonths from "./helpers/generateMonths";
import useQuery from "../../shared/helpers/useQuery";
import { parse, format } from "date-fns";

import { useDispatch, useSelector } from "react-redux";
import { SUPPLIER_FILES_REQUESTED } from "./actions";

import Box from "@material-ui/core/Box";
import FileUploadIcon from "../../shared/components/svg/FileUpload";
import FixedFab from "../../shared/components/FixedFab";
import Snackbar from "@material-ui/core/Snackbar";

import Alert from "../../shared/components/Alert";
import Statuses from "../../shared/components/Statuses";
import MonthSelector from "./components/MonthSelector";
import DataTable from "../../shared/components/DataTable";
import Dashboard from "./components/Dashboard";
import UploadDialog from "./components/UploadDialog";
import CheckIcon from "./components/CheckIcon";

const useStyles = makeStyles(theme => ({
  exetendedIcon: {
    marginRight: theme.spacing(0.5)
  }
}));

function SupplierFiles() {
  // redux bindings
  const dispatch = useDispatch();
  const { error, loading, data } = useSelector(state => state.supplierFiles);

  // date periods
  const parseDate = (year, month) =>
    parse(`${year}-${month}`, "yyyy-MM", new Date());

  const availableDates = generateMonths();
  const month = useQuery().get("month") || format(new Date(), "MM");
  const year = useQuery().get("year") || format(new Date(), "yyyy");
  const selectedDate = parseDate(year, month);

  useEffect(() => {
    const selectedDate = parseDate(year, month);
    dispatch({ type: SUPPLIER_FILES_REQUESTED, date: selectedDate });
  }, [dispatch, year, month]);

  // selected items
  const [selectedIds, setSelectedIds] = useState([]);

  // modal dialog
  const [modalOpen, setModalOpen] = useState(false);

  function handleClickOpen() {
    setModalOpen(true);
  }

  function handleModalClose() {
    setModalOpen(false);
  }

  function filename(path) {
    let file = path.split("/");
    return file[file.length - 1];
  }

  // snackbar
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("");

  function triggerSnackBar(message, severity) {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  }

  const classes = useStyles();

  return (
    <React.Fragment>
      <FixedFab onClick={handleClickOpen}>
        <FileUploadIcon className={classes.exetendedIcon} />
        Upload file
      </FixedFab>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
      >
        <Alert severity={snackbarSeverity} elevation={6}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
      <UploadDialog
        onClose={handleModalClose}
        open={modalOpen}
        availableDates={availableDates}
        onUploadSuccess={() =>
          triggerSnackBar(
            "Upload Successful. Please allow a few minutes for your file to be processed",
            "success"
          )
        }
        onUploadFailure={() =>
          triggerSnackBar(
            "There was a problem uploading your file.  Please try again later.",
            "error"
          )
        }
      />
      <MonthSelector months={availableDates} selectedDate={selectedDate} />
      <Box my={2}>
        <Statuses loading={loading} noData={!data.length} error={error}>
          <React.Fragment>
            <Box my={5}>
              <Dashboard
                onReset={() => setSelectedIds([])}
                selectedIds={selectedIds}
                data={data}
                selectedDate={selectedDate}
              />
            </Box>
            <DataTable
              dateTimeSelectors={["timestamp"]}
              defaultSortSelector={"timestamp"}
              colorReverse
              title="Supplier Files"
              data={data.map(row => ({
                timestamp: row.createdDate,
                supplier: row.errors ? "N/A" : row.supplier,
                id: row.id,
                filename: filename(row.filename),
                total: `£${row.netTotal.toFixed(2)}`,
                supplierCheck: (
                  <CheckIcon supplierCheck={row.supplierConsistencyCheck} />
                )
              }))}
              columns={[
                { title: "Supplier", selector: "supplier" },
                { title: "Filename", selector: "filename" },
                { title: "Total", selector: "total" },
                { title: "Timestamp", selector: "timestamp" },
                { title: "Consistency check", selector: "supplierCheck" }
              ]}
              selectedRowIds={selectedIds}
              onRowSelect={setSelectedIds}
              selectAllOnMount={true}
            />
          </React.Fragment>
        </Statuses>
      </Box>
    </React.Fragment>
  );
}

export default SupplierFiles;
