import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import {
  CircularProgress,
  InputLabel,
  makeStyles,
  MenuItem,
  Select
} from "@material-ui/core";

import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";

import { createManualAllocation, fetchBrandToEntity } from "../api";
import FormControl from "@material-ui/core/FormControl";
import DialogContentText from "@material-ui/core/DialogContentText";
import TextField from "@material-ui/core/TextField";
import { useAuth0 } from "../../../react-auth0-spa";

const useStyles = makeStyles(theme => ({
  gridContainer: {
    paddingBottom: theme.spacing(1)
  },
  submitButton: {
    color: theme.palette.success.contrastText,
    backgroundColor: theme.palette.success.main,
    "&:hover": {
      backgroundColor: theme.palette.success.dark
    }
  }
}));

function AllocateEntityDialog({
  open,
  onClose,
  selectedRows,
  onUpdateSuccess,
  onUpdateFailure
}) {
  const { user } = useAuth0();

  const [account, setAccount] = useState(null);
  const [brandToEntity, setBrandToEntity] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [options, setOptions] = useState([]);
  const [resellerAccountRequired, setResellerAccountRequired] = useState(true);

  useEffect(() => {
    if (open) {
      setIsLoading(true);
      fetchBrandToEntity().then(data => {
        setOptions(data);
        setIsLoading(false);
      });
    }
  }, [open]);

  //Validate form input
  function isValid() {
    return (
      selectedRows.length > 0 &&
      brandToEntity !== "" &&
      (brandToEntity.entity === "ALL" || account !== null)
    );
  }

  const clearData = () => {
    setAccount(null);
    setBrandToEntity("");
    setResellerAccountRequired(true);
  };

  function handleClose() {
    clearData();
    onClose();
  }

  function handleAccountResellerChange(e) {
    setAccount(e.target.value);
  }

  function handleBrandChange(e) {
    if (e.target.value && e.target.value.entity === "ALL") {
      setResellerAccountRequired(false);
    } else {
      setResellerAccountRequired(true);
    }

    setBrandToEntity(e.target.value);
  }

  function generateOptions() {
    return options.map(brandToEntity => {
      return (
        <MenuItem
          value={brandToEntity}
          id={brandToEntity.brand}
          key={brandToEntity.brand}
        >
          {brandToEntity.brand}
        </MenuItem>
      );
    });
  }

  async function handleSubmit() {
    let manualAllocations = [];

    //Build request list
    for (let i = 0; i < selectedRows.length; i++) {
      let manualAllocation = {
        miDataId: selectedRows[i].id,
        ssid: selectedRows[i].ssid,
        brandToEntity: brandToEntity,
        resellerAcct: account,
        username: user.name
      };

      manualAllocations.push(manualAllocation);
    }

    //Invoke requests and check for any failures, and relay to UI
    let response = await createManualAllocation(manualAllocations);

    if (response) {
      onUpdateSuccess(
        "The manual entity allocations have successfully been created."
      );
    } else {
      onUpdateFailure([
        "There has been an error updating your record(s), please review and retry later."
      ]);
    }

    handleClose();
  }

  const classes = useStyles();

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="create-schedule-form"
    >
      <DialogTitle id="charge-info-update-form" disableTypography>
        <Box component="h2" fontSize="1.5rem" fontWeight="400" m={0} pb={5}>
          Allocate Entity
        </Box>
        <Box component="h3" fontSize="1rem" fontWeight="500" m={0}>
          Selected IDs: {selectedRows.length}
        </Box>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          Please provide the details for manual entity allocation, review your
          selection and click submit to create the manual entity allocation
          records.
        </DialogContentText>
      </DialogContent>
      <form>
        <DialogContent>
          <Box pt={1}>
            <Grid
              container
              spacing={2}
              classes={{ container: classes.gridContainer }}
            >
              <Grid item xs={12}>
                <FormControl fullWidth label={"Brand *"} variant="outlined">
                  <InputLabel>Brand</InputLabel>
                  <Select
                    required
                    label="Brand *"
                    name="brand"
                    onChange={handleBrandChange}
                    value={brandToEntity}
                    renderValue={selected => selected.brand}
                  >
                    {isLoading ? (
                      <CircularProgress size={20} />
                    ) : (
                      generateOptions()
                    )}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12}>
                <FormControl fullWidth label={"Entity"} variant="outlined">
                  <TextField
                    required
                    disabled
                    label={"Entity"}
                    name="entity"
                    value={brandToEntity ? brandToEntity.entity : ""}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12}>
                <FormControl
                  fullWidth
                  label={"Reseller Account"}
                  variant="outlined"
                >
                  <TextField
                    required={resellerAccountRequired}
                    label={"Reseller Account"}
                    name="resellerAccount"
                    onChange={handleAccountResellerChange}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            className={classes.submitButton}
            size="large"
            variant="contained"
            onClick={handleSubmit}
            disabled={!isValid()}
          >
            Submit
          </Button>
          <Button onClick={handleClose} size="large" variant="text">
            Cancel
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

AllocateEntityDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  selectedRows: PropTypes.array.isRequired,
  onUpdateSuccess: PropTypes.func.isRequired,
  onUpdateFailure: PropTypes.func.isRequired
};

export default AllocateEntityDialog;
