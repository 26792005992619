import React from "react";
import Box from "@material-ui/core/Box";
import Link from "@material-ui/core/Link";

import { useAuth0 } from "../react-auth0-spa";

function User() {
  const { user, logout } = useAuth0();

  const logoutWithRedirect = () =>
    logout({
      returnTo: window.location.origin
    });

  if (!user) {
    return null;
  }

  return (
    <Box fontSize={14} px={3} fontWeight={500}>
      Logged in as {user.name} -{" "}
      <Link href="#" onClick={logoutWithRedirect} color="secondary">
        Logout
      </Link>
    </Box>
  );
}

export default User;
