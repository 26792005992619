import React, { useEffect } from "react";
import PropTypes from "prop-types";

import { useDispatch, useSelector } from "react-redux";
import { SUPPLIERS_REQUESTED } from "../../../shared/actions";
import { createSchedule } from "../api";

import { makeStyles } from "@material-ui/core";
import runDays from "../helpers/getRunDays";
import isValidHost from "../../../shared/helpers/validators/isValidHost";

import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
//import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
import MenuItem from "@material-ui/core/MenuItem";

import { TextField } from "formik-material-ui";

import { Formik, Form } from "formik";

const useStyles = makeStyles(theme => ({
  gridContainer: {
    paddingBottom: theme.spacing(1)
  },
  submitButton: {
    color: theme.palette.success.contrastText,
    backgroundColor: theme.palette.success.main,
    "&:hover": {
      backgroundColor: theme.palette.success.dark
    }
  }
}));

function CreateDialog({ open, onClose, onCreateFailure }) {
  const dispatch = useDispatch();
  const suppliers = useSelector(state => state.app.suppliers);

  // fetch a list of suppliers from the API each time that the dialog opens
  useEffect(() => {
    open && dispatch({ type: SUPPLIERS_REQUESTED });
  }, [dispatch, open]);

  function handleClose() {
    onClose();
  }

  async function handleSubmit(values) {
    try {
      await createSchedule(values);
    } catch (e) {
      onCreateFailure();
      console.error(e);
    }
    handleClose();
  }

  const formatRunDay = day => (day < 10 ? "0" + day : "" + day);
  const classes = useStyles();

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="create-schedule-form"
    >
      <DialogTitle id="supplier-files-upload-form" disableTypography>
        <Box component="h2" fontSize="1.5rem" fontWeight="400" m={0}>
          Create Schedule
        </Box>
      </DialogTitle>
      <Formik
        validateOnBlur={false}
        validateOnChange={false}
        initialValues={{
          scheduleName: "",
          supplier: "none",
          runDate: "none",
          host: "",
          port: "",
          username: "",
          password: ""
        }}
        validate={values => {
          const REQUIRED = "Field is required";
          const errors = {};
          if (!values.scheduleName) {
            errors.scheduleName = REQUIRED;
          }

          if (values.supplier === "none") {
            errors.supplier = REQUIRED;
          }

          if (values.runDate === "none") {
            errors.runDate = REQUIRED;
          }

          if (!values.host) {
            errors.host = REQUIRED;
          } else if (!isValidHost(values.host)) {
            errors.host = "Must be a valid host eg. https://myserver.com";
          }

          if (!values.port) {
            errors.port = REQUIRED;
          } else if (!/^\d{4}$/.test(values.port)) {
            errors.port = "Must be a valid port eg. 8080";
          }

          if (!values.username) {
            errors.username = REQUIRED;
          }

          if (!values.password) {
            errors.password = REQUIRED;
          }

          return errors;
        }}
        onSubmit={handleSubmit}
      >
        {({ submitForm, isSubmitting }) => (
          <Form>
            <DialogContent>
              {/* <DialogContentText>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean
                euismod bibendum laoreet. Proin gravida dolor sit amet lacus
                accumsan et viverra justo commodo.
              </DialogContentText> */}
              <Box pt={1}>
                <Grid
                  container
                  spacing={2}
                  classes={{ container: classes.gridContainer }}
                >
                  <Grid item xs={12}>
                    <TextField
                      name="scheduleName"
                      type="text"
                      fullWidth
                      size="small"
                      label="Schedule Name"
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      name="supplier"
                      type="text"
                      select
                      fullWidth
                      size="small"
                      variant="outlined"
                    >
                      <MenuItem disabled value="none">
                        {suppliers.length > 0
                          ? "Select supplier"
                          : "Fetching suppliers..."}
                      </MenuItem>
                      {suppliers.map(({ name, code }, i) => (
                        <MenuItem key={i} value={code}>
                          {name}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      name="runDate"
                      type="text"
                      select
                      fullWidth
                      size="small"
                      variant="outlined"
                    >
                      <MenuItem value="none" disabled>
                        Select Run Date
                      </MenuItem>
                      {runDays.map(day => (
                        <MenuItem key={day} value={day}>
                          {formatRunDay(day)}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      name="host"
                      type="text"
                      fullWidth
                      size="small"
                      label="Host"
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      name="port"
                      type="text"
                      fullWidth
                      size="small"
                      label="Port"
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      name="username"
                      type="text"
                      fullWidth
                      size="small"
                      label="Username"
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      name="password"
                      type="password"
                      fullWidth
                      size="small"
                      label="Password"
                      variant="outlined"
                    />
                  </Grid>
                </Grid>
              </Box>
            </DialogContent>
            <DialogActions>
              <Button
                className={classes.submitButton}
                size="large"
                variant="contained"
                onClick={submitForm}
                disabled={isSubmitting}
              >
                Upload
              </Button>
              <Button
                onClick={handleClose}
                size="large"
                variant="text"
                disabled={isSubmitting}
              >
                Cancel
              </Button>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
}

CreateDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onCreateFailure: PropTypes.func.isRequired
};

export default CreateDialog;
