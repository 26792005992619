export const API_BASE_URL =
  process.env.REACT_APP_API_BASE_URL || process.env.REACT_APP_JS_API;
export const API_PLATFORM_BASE_URL =
  process.env.REACT_APP_API_PLATFORM_BASE_URL ||
  process.env.REACT_APP_PLATFORM_API;
export const AUTH_DOMAIN =
  process.env.REACT_APP_AUTH_DOMAIN || process.env.REACT_APP_DOMAIN;
export const AUTH_CLIENT_ID =
  process.env.REACT_APP_AUTH_CLIENT_ID || process.env.REACT_APP_CLIENT_ID;
export const AUTH_AUDIENCE =
  process.env.REACT_APP_AUTH_AUDIENCE || process.env.REACT_APP_AUDIENCE;
