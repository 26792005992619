import axios from "../../../api";

// API
export function fetchSchedules() {
  return axios.request(`schedules`).then(({ data }) => data);
}

export function createSchedule(schedule) {
  return axios.post(`schedules`, schedule);
}
