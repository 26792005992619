export function generateRunMonthsArray() {
  return [
    "01",
    "02",
    "03",
    "04",
    "05",
    "06",
    "07",
    "08",
    "09",
    "10",
    "11",
    "12"
  ];
}

export function formatMonth(month) {
  if (month < 10) {
    month = "0" + month;
  }
  return month;
}
