//Todo: Replace with a saga-based db look-up
function generateReportTypes() {
  return [
    "MAIN_SUMMARY_REPORT",
    "BROKEN_LINKS_REPORT",
    "BROKEN_LINKS_SUMMARY_REPORT",
    "NOT_ON_BASE_REPORT",
    "ONE_OFF_CHARGES_REPORT",
    "ONE_OFF_CHARGES_SUMMARY_REPORT",
    "GAINS_AND_LOSSES_REPORT",
    "GAINS_AND_LOSSES_SUMMARY_REPORT",
    "SUPPLIER_CONSISTENCY_CHECK_REPORT",
    "BT_JOURNAL_REPORT",
    "TTB_JOURNAL_REPORT",
    "UNMATCHED_SUPPLIER_CHARGES_REPORT",
    "DUPLICATE_CHARGES_REPORT",
    "LOSS_MAKING_BROADBAND_SUMMARY_REPORT",
    "LOSS_MAKING_BROADBAND_DETAIL_REPORT",
    "MI_BROADBAND_REPORT",
    "CIRCUITS_MISSING_REVENUE_REPORT",
    "LLU_RENTALS_NO_BROADBAND_REPORT",
    "TTB_REBATE_NO_CLAIM_REPORT",
    "MI_EXTRACT_REPORT",
    "RECON_STATUS_BY_SUPPLIER_REPORT",
    "ALL_SUPPLIER_CREDITS_REPORT",
    "AM_PM_CHARGES_REPORT",
    "BASE_REPORT_EXTRACT_REPORT",
    "ONE_OFFS_TO_BILLING_REPORT",
    "INVOICE_TOTAL_AND_CONSISTENCY_REPORT"
  ];
}

export default generateReportTypes();
