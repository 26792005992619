import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

function FileUpload(props) {
  return (
    <SvgIcon {...props}>
      <path d="M9 16h6v-6h4l-7-7-7 7h4zm-4 2h14v2H5z" />
    </SvgIcon>
  );
}

export default FileUpload;
