import axios from "../../../api";
import javaApiInstance from "../../../api/javaApiInstance";

// API
export function fetchReports() {
  return axios.request(`reports`).then(({ data }) => data);
}

export function createSignedUrlForGet(fileLocation) {
  return javaApiInstance
    .post("report-download-url", { fileLocation })
    .then(({ data }) => data.signedUrl);
}

export function createReportRequest(values) {
  let data = {
    reportType: values.reportType,
    supplier: values.supplier,
    month: values.month,
    year: values.year
  };

  return javaApiInstance
    .post("reports/report-request", data, {
      headers: {
        "Content-Type": "application/json"
      }
    })
    .then(({ res }) => res);
}
