import React from "react";
import { Box, IconButton } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";

function Actions({
  onRowAction,
  rowData,
  setDeleteDialogOpen,
  setEditDialogOpen,
  disableEdit
}) {
  const handleDeleteClick = () => {
    onRowAction(rowData);
    setDeleteDialogOpen(true);
  };

  const handleEditClick = () => {
    onRowAction(rowData);
    setEditDialogOpen(true);
  };

  return (
    <Box>
      {!disableEdit && (
        <IconButton onClick={() => handleEditClick(rowData)}>
          <EditIcon />
        </IconButton>
      )}

      <IconButton onClick={() => handleDeleteClick(rowData)}>
        <DeleteIcon />
      </IconButton>
    </Box>
  );
}

export default Actions;
