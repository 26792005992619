// eg with maxLength 20
// "an inconveniently long filename.csv" -> "an inconve...ame.csv"

export default function truncateFilename(str, maxLength = 30) {
  if (str.length <= maxLength) {
    return str;
  }

  const separator = "...";

  const chunks = str.split(".");
  const extLength = chunks.length > 1 ? chunks[chunks.length - 1].length : 0;

  const rightSplitPoint = extLength + 4;
  const leftSplitPoint = maxLength - rightSplitPoint - separator.length;

  return (
    str.substr(0, leftSplitPoint) +
    separator +
    str.substr(str.length - rightSplitPoint)
  );
}
