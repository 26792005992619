import { useApi } from "../../japi";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import React, { useState } from "react";

export const DslFilterSwitch = ({
  field,
  switchFilter,
  switchLabel,
  initialState
}) => {
  const { getCustomFilter, updateCustomFilter } = useApi();
  const [switchState, setSwitchState] = useState(initialState);

  const toggle = () => {
    setSwitchState(!switchState);
    let filter = new Map();

    if (getCustomFilter().size > 0) {
      filter = getCustomFilter();
    }
    if (switchState) {
      filter.set(field, switchFilter);
    } else {
      filter.delete(field);
    }

    updateCustomFilter(new Map(filter));
  };

  return (
    <React.Fragment>
      <FormControlLabel
        value="start"
        control={
          <Switch
            onChange={() => {
              toggle();
            }}
          />
        }
        checked={switchState}
        label={switchLabel}
        labelPlacement="start"
      />
    </React.Fragment>
  );
};
