import React, { useState } from "react";
import MUIDataTable from "mui-datatables";
import { useApi } from "../../japi";
import SaveIcon from "@material-ui/icons/Save";
import Button from "@material-ui/core/Button";
import throbber from "../../assets/throbber.gif";
import download from "downloadjs";
import Alert from "./Alert";
import Snackbar from "@material-ui/core/Snackbar";
import { objArrToCSV } from "../helpers/objArrToCsv";

export const ContextDataTable = ({
  columns,
  props,
  handleRowsSelected,
  isRowSelectable,
  allowSearch
}) => {
  const {
    updateFilter,
    updatePage,
    updateSize,
    getFullDataExport,
    search,
    data,
    paging,
    headers,
    loading,
    emptyBodyMessage
  } = useApi();
  const [tableData, setTableData] = useState(data);
  const [term, setTerm] = useState("");
  const loader = [[<img src={throbber} alt={"A spinner"} />]];
  const [isLoading, setIsLoading] = useState(loading);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const filter = applyFilters => {
    let filterList = applyFilters();
    let map = new Map();

    filterList.forEach((f, index) => {
      //Support for multi-select filters, e.g. array of selected opts
      if (f.length > 0) {
        let arr = [];
        const { name, filterPath } = columns[index];
        const filterMapper = columns[index]?.options?.filterMapper;

        for (let item of f) {
          arr.push(filterMapper ? filterMapper(item) : item);
        }
        map.set(filterPath ? filterPath : name, arr);
      }
    });
    updateFilter(map);
  };

  //Pad loader data array with empty value if props are set due to tick box offset
  function getLoader() {
    if (props != null) {
      return [["", [loader]]];
    } else {
      return [[loader]];
    }
  }

  const options = {
    count: paging.totalElements,
    rowsPerPage: paging.size,
    rowsPerPageOptions: [20, 50, 100, 500],
    onDownload: (buildHead, buildBody, columns, data) => {
      setSnackbarSeverity("info");
      setSnackbarMessage(
        "Your full data export CSV is being prepared, please wait a few moments and " +
          "the file will automatically be downloaded to your browser. Please Note: large datasets may take " +
          "up to several minutes to generate."
      );
      setSnackbarOpen(true);
      getFullDataExport().then(response => {
        if (response !== undefined) {
          response
            .json()
            .then(res => {
              // Header
              const type = Object.keys(res._embedded)[0];
              // Data
              let embeddedElement = res._embedded[type];
              embeddedElement.forEach(obj => delete obj["_links"]);
              download(
                objArrToCSV(embeddedElement),
                "all-data-export.csv",
                "text/csv"
              );
            })
            .catch(() => {
              setSnackbarSeverity("error");
              setSnackbarMessage(
                "An error has occurred whilst generating your full data export. Please try again later."
              );
              setSnackbarOpen(true);
            });
        }
      });

      return false; //delegate to callback body above
    },
    selectableRows:
      props != null &&
      props.selectableRows != null &&
      data.length > 0 &&
      !isLoading
        ? props.selectableRows
        : "none",
    selectableRowsOnClick:
      props != null &&
      props.selectableRowsOnClick === true &&
      data.length > 0 &&
      !isLoading,
    onRowSelectionChange(rowSelected, allRows) {
      const selected = allRows.map(item => {
        return data[item.index];
      });
      handleRowsSelected(selected);
    },
    isRowSelectable: isRowSelectable
      ? dataIndex => isRowSelectable(data[dataIndex])
      : undefined,
    customToolbarSelect: () => {},
    serverSide: true,
    print: false,
    searchAlwaysOpen: !!allowSearch,
    search: !!allowSearch,
    textLabels: {
      body: {
        noMatch: emptyBodyMessage
      }
    },
    // Filtering
    confirmFilters: true,
    customFilterDialogFooter: (currentFilterList, applyNewFilters) => {
      return (
        <div style={{ marginTop: "40px" }}>
          <Button
            variant="contained"
            color={"primary"}
            startIcon={<SaveIcon />}
            onClick={() => filter(applyNewFilters)}
            align={"right"}
          >
            Apply Filters
          </Button>
        </div>
      );
    },

    onFilterChange: (column, filterList, type) => {
      if (type === "chip") {
        let newFilters = () => filterList;
        filter(newFilters);
      }
    },

    onTableChange: (action, tableState) => {
      switch (action) {
        case "changePage":
          if (tableState.page !== -1) {
            setIsLoading(true);
            updatePage(tableState.page);
          }
          break;
        case "changeRowsPerPage":
          tableState.page = 0;
          updateSize(tableState.rowsPerPage);
          break;
        case "propsUpdate":
          setIsLoading(loading);
          setTableData(data);
          break;
        case "search":
          setTerm(tableState.searchText);
          break;
        case "onSearchClose":
          setIsLoading(true);
          setTerm("");
          search("");
          break;
        default:
        // do nothing
      }
    },
    searchProps: {
      onKeyDown: e => {
        if (e.keyCode === 13) {
          search(term);
        }
      }
    }
  };
  return (
    <React.Fragment>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={10000}
        onClose={() => setSnackbarOpen(false)}
      >
        <Alert severity={snackbarSeverity} elevation={6}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
      <MUIDataTable
        columns={columns ? columns : headers}
        data={isLoading ? getLoader() : tableData}
        options={options}
      />
    </React.Fragment>
  );
};
