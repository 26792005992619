import { SUPPLIERS_REQUESTED, SUPPLIERS_SUCCESS } from "../actions";

const initialState = {
  suppliers: []
};

export default function(state = initialState, action) {
  switch (action.type) {
    case SUPPLIERS_REQUESTED: {
      return { ...state, suppliers: [] };
    }
    case SUPPLIERS_SUCCESS:
      return { ...state, suppliers: action.payload };
    default:
      return state;
  }
}
