import React, { useState } from "react";
import { ApiProvider } from "../../../japi";
import { ContextDataTable } from "../../../shared/components/ContextDataTable";
import Actions from "../components/Actions";
import AddIcon from "@material-ui/icons/Add";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core";
import CreateDialog from "../components/CreateDialog";
import DeleteDialog from "../components/DeleteDialog";
import Snackbar from "../components/SnackbarCustom";

const useStyles = makeStyles(theme => ({
  extendedIcon: {
    marginRight: theme.spacing(0.5)
  },
  categoryButton: {
    marginBottom: "0.5rem"
  }
}));

function BrandToEntity() {
  const classes = useStyles();

  const columns = [
    {
      name: "brand",
      label: "Brand",
      filterPath: "brand",
      options: {
        filter: true,
        sort: false,
        filterType: "textField"
      },
      formOptions: {
        editable: true,
        required: true
      }
    },
    {
      name: "entity",
      label: "Entity",
      filterPath: "entity",
      options: {
        filter: true,
        sort: false,
        filterType: "textField"
      },
      formOptions: {
        editable: true,
        required: true
      }
    },
    {
      name: "",
      label: "Actions",
      options: {
        customBodyRender: (value, data) => {
          const { rowData } = data;

          if (!Array.isArray(rowData[0])) {
            return (
              <Actions
                rowData={rowData}
                disableEdit={true}
                onRowAction={onRowAction}
                setDeleteDialogOpen={setDeleteDialogOpen}
              />
            );
          }
        },
        sort: false,
        filter: false
      }
    }
  ];

  const [lastUpdated, setLastUpdated] = useState();
  const [createDialogOpen, setCreateDialogOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [id, setId] = useState("");

  const [snackbarMessage, setSnackbarMessage] = useState();
  const [snackbarSuccess, setSnackbarSuccess] = useState(true);
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const handleDataChange = () => {
    setLastUpdated(Date.now());
  };

  const handleCreateClick = () => {
    setCreateDialogOpen(true);
  };

  const handleClose = () => {
    setCreateDialogOpen(false);
    setDeleteDialogOpen(false);
  };

  const onRowAction = row => {
    setId(`${row[0]}-${row[1]}`);
  };

  const updateSnackbar = (success, message) => {
    setSnackbarSuccess(success);
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };

  return (
    <React.Fragment>
      <Snackbar
        snackbarMessage={snackbarMessage}
        snackbarSuccess={snackbarSuccess}
        snackbarOpen={snackbarOpen}
        setSnackbarOpen={setSnackbarOpen}
      />

      <Button
        className={classes.categoryButton}
        onClick={handleCreateClick}
        variant="contained"
        color={"primary"}
        startIcon={<AddIcon>className={classes.extendedIcon}</AddIcon>}
        align={"right"}
      >
        Add row
      </Button>
      <ApiProvider initialEndpoint="brand-to-entity" lastUpdated={lastUpdated}>
        <ContextDataTable columns={columns} />
      </ApiProvider>

      <CreateDialog
        open={createDialogOpen}
        endpoint={"brand-to-entity"}
        handleClose={handleClose}
        form={columns}
        onSuccess={handleDataChange}
        updateSnackbar={updateSnackbar}
      />

      <DeleteDialog
        open={deleteDialogOpen}
        endpoint={"brand-to-entity"}
        handleClose={handleClose}
        onSuccess={handleDataChange}
        updateSnackbar={updateSnackbar}
        id={id}
      />
    </React.Fragment>
  );
}

export default BrandToEntity;
