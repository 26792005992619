import javaApiInstance from "../../../api/javaApiInstance";

export async function fetchBrandToEntity() {
  return javaApiInstance.get("manual-entity-allocation/brand-options").then(
    ({ data }) => data,
    () => {
      return [];
    }
  );
}

export async function createManualAllocation(list) {
  let success;

  try {
    await javaApiInstance.post(`manual-entity-allocation/create`, list).then(
      response => {
        if (response.status === 204 || response.status === 200) {
          success = true;
        } else {
          success = false;
        }
      },
      () => {
        success = false;
      }
    );
  } catch (e) {
    success = false;
  }

  return success;
}

export async function updateSsidAndCli(list) {
  let success;

  try {
    await javaApiInstance.post(`mi-data/allocate-ssid`, list).then(
      response => {
        if (response.status === 204 || response.status === 200) {
          success = true;
        } else {
          success = false;
        }
      },
      () => {
        success = false;
      }
    );
  } catch (e) {
    success = false;
  }

  return success;
}
