import React, { Fragment } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  makeStyles
} from "@material-ui/core";

import { deleteEntity } from "../api";

const useStyles = makeStyles(theme => ({
  submitButton: {
    color: theme.palette.success.contrastText,
    backgroundColor: theme.palette.error.main,
    "&:hover": {
      backgroundColor: theme.palette.error.dark
    }
  }
}));

function DeleteDialog({
  open,
  handleClose,
  endpoint,
  onSuccess,
  id,
  updateSnackbar
}) {
  const classes = useStyles();

  const handleDelete = async () => {
    let response = await deleteEntity(id, endpoint);

    if (response) {
      updateSnackbar(true, "Record was deleted.");
      onSuccess();
    } else {
      updateSnackbar(
        false,
        "Could not delete record. Please try later or contact administrator."
      );
    }

    handleClose();
  };

  return (
    <Fragment>
      <Dialog open={open}>
        <DialogTitle>Delete row</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete row?
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          <Button
            onClick={handleDelete}
            size="large"
            variant="contained"
            className={classes.submitButton}
          >
            Yes
          </Button>

          <Button onClick={handleClose} size="large" variant="contained">
            No
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
}

export default DeleteDialog;
