export const SCHEDULES_REQUESTED = "SCHEDULES_REQUESTED";
export const SCHEDULES_SUCCESS = "SCHEDULES_SUCCESS";
export const SCHEDULES_ERROR = "SCHEDULES_ERROR";

export function fetchSchedulesSuccess(payload) {
  return {
    type: SCHEDULES_SUCCESS,
    payload
  };
}
