import React from "react";
import PropTypes from "prop-types";

import Alert from "../../shared/components/Alert";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import throbber from "../../assets/throbber.gif";

function Statuses({ loading, error, noData, children }) {
  if (loading) {
    return (
      <Box color="grey.400">
        <img src={throbber} alt={"A spinner"} />
      </Box>
    );
  }

  if (error) {
    return (
      <Alert severity="error">
        There was an error fetching data. Please try again later.
      </Alert>
    );
  }

  if (noData) {
    return <Typography size="body1">There is no data to display.</Typography>;
  }

  return children;
}

Statuses.propTypes = {
  loading: PropTypes.bool,
  error: PropTypes.bool,
  noData: PropTypes.bool,
  children: PropTypes.node
};

export default Statuses;
