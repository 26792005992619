import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";

import TopBar from "./TopBar";

const drawerWidth = 250;
const appBarContentHeight = 60;
const appBarBorderWidth = 4;
const appBarTotalHeight = appBarContentHeight + appBarBorderWidth;

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex"
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1
  },
  appBarToolBar: {
    borderBottom: `${appBarBorderWidth}px solid orange`,
    height: `${appBarTotalHeight}px`,
    padding: theme.spacing(0, 1)
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0
  },
  drawerPaper: {
    width: drawerWidth,
    border: "none",
    boxShadow: "4px 0px 6px rgba(0, 0, 0, 0.149019607843137)"
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(2, 4)
  },
  toolbar: {
    ...theme.mixins.toolbar,
    height: `${appBarTotalHeight}px`
  }
}));

function Layout({ children, sidebar }) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <AppBar position="fixed" className={classes.appBar} elevation={0}>
        <Toolbar className={classes.appBarToolBar}>
          <TopBar />
        </Toolbar>
      </AppBar>
      <Drawer
        className={classes.drawer}
        variant="permanent"
        classes={{ paper: classes.drawerPaper }}
      >
        <div className={classes.toolbar} />
        {sidebar}
      </Drawer>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        {children}
      </main>
    </div>
  );
}

export default Layout;
