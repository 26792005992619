import React from "react";
import Box from "@material-ui/core/Box";
import List from "@material-ui/core/List";

import NavItem from "../nav/NavItem";
import NavLink from "../nav/NavLink";
import { ListItem, makeStyles } from "@material-ui/core";
import SettingsIcon from "@material-ui/icons/Settings";

const useStyles = makeStyles(theme => ({
  lastElement: {
    position: "fixed",
    bottom: 0,
    paddingBottom: 10
  }
}));

function SideBarMain() {
  const classes = useStyles();

  return (
    <Box>
      <Box fontSize={18} p={2} display="flex" justifyContent="flex-start">
        Menu
      </Box>
      <List component="nav" disablePadding>
        <NavItem>
          <NavLink to="/dashboard">Dashboard</NavLink>
        </NavItem>
        <NavItem>
          <NavLink to="/supplier-files">Supplier Files</NavLink>
        </NavItem>
        <NavItem>
          <NavLink to="/supplier-data">Supplier Data Search</NavLink>
        </NavItem>
        <NavItem>
          <NavLink to="/mi-data">MI Data Search</NavLink>
        </NavItem>
        <NavItem>
          <NavLink to="/main-summary">Main Summary Data Search</NavLink>
        </NavItem>
        <NavItem>
          <NavLink to="/reports">Reports</NavLink>
        </NavItem>

        <ListItem className={classes.lastElement}>
          <Box>
            <NavLink to="/manual-entity-allocation">
              <SettingsIcon />
            </NavLink>
          </Box>
        </ListItem>
      </List>
    </Box>
  );
}

export default SideBarMain;
