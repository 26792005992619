export const CHARGE_INFO_REQUESTED = "CHARGE_INFO_REQUESTED";
export const CHARGE_INFO_SUCCESS = "CHARGE_INFO_SUCCESS";
export const CHARGE_INFO_ERROR = "CHARGE_INFO_ERROR";

export function fetchChargeInfoSuccess(payload) {
  return {
    type: CHARGE_INFO_SUCCESS,
    payload
  };
}
