import React, { Fragment, useEffect, useState } from "react";
import RequestSummaryDialog from "./components/RequestSummaryDialog";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "../../shared/components/Alert";
import FixedFab from "../../shared/components/FixedFab";
import UpdateIcon from "@material-ui/icons/Update";
import { makeStyles } from "@material-ui/core";
import Statuses from "../../shared/components/Statuses";
import ReconciliationStatus from "./components/ReconciliationStatus";
import { useDispatch, useSelector } from "react-redux";
import { RECONCILIATION_STATUS_REQUESTED } from "./actions";
import Dashboard from "./components/Dashboard";
import moment from "moment/moment";
import Box from "@material-ui/core/Box";

const useStyles = makeStyles(theme => ({
  gridContainer: {
    paddingBottom: theme.spacing(1)
  },
  submitButton: {
    color: theme.palette.success.contrastText,
    backgroundColor: theme.palette.success.main,
    "&:hover": {
      backgroundColor: theme.palette.success.dark
    }
  }
}));

function DashboardView() {
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarSuccess, setSnackbarSuccess] = useState(false);
  const [snackbarMessages, setSnackbarMessages] = useState([]);
  const [open, setOpen] = useState(false);

  const dispatch = useDispatch();
  const {
    error,
    loading,
    steps,
    overallStatus,
    runningTime,
    averageTime,
    reconDate
  } = useSelector(state => state.dashboard);

  useEffect(() => {
    dispatch({ type: RECONCILIATION_STATUS_REQUESTED });
    // Set up an interval to fetch data every second
    const intervalId = setInterval(
      () => dispatch({ type: RECONCILIATION_STATUS_REQUESTED }),
      1000
    );

    // Cleanup function to clear the interval when the component is unmounted
    return () => clearInterval(intervalId);
  }, [dispatch]); // The empty dependency array ensures that this effect runs only once after the initial render

  //Styles
  const classes = useStyles();

  function handleClickOpen() {
    setOpen(true);
  }

  function handleClose() {
    setOpen(false);
  }

  return (
    <Statuses loading={loading} noData={!steps.length} error={error}>
      {overallStatus && (
        <Box my={3}>
          <Dashboard
            status={overallStatus}
            runningTime={moment.duration(runningTime, "seconds")}
            averageTime={moment.duration(averageTime, "seconds")}
            steps={steps.filter(step => step.status === "COMPLETED")}
            reconDate={reconDate}
          />
        </Box>
      )}
      <ReconciliationStatus steps={steps} />
      <Fragment>
        <FixedFab onClick={handleClickOpen}>
          <UpdateIcon className={classes.exetendedIcon} />
          Request Main Summary
        </FixedFab>
        <RequestSummaryDialog
          open={open}
          onClose={handleClose}
          onCreateFailure={errors => {
            setSnackbarOpen(true);
            setSnackbarSuccess(false);
            if (errors !== undefined) {
              setSnackbarMessages(errors);
            }
          }}
          onCreateSuccess={() => {
            setSnackbarOpen(true);
            setSnackbarSuccess(true);
            setSnackbarMessages([]);
          }}
        />
        <Snackbar
          open={snackbarOpen}
          autoHideDuration={6000}
          onClose={() => setSnackbarOpen(false)}
        >
          {snackbarSuccess === true ? (
            <Alert severity="success" elevation={6}>
              Your Main Summary request has been received.
            </Alert>
          ) : (
            <Alert severity="error" elevation={6}>
              {snackbarMessages.size > 0 ? (
                snackbarMessages.map((error, i) => (
                  <div id={i}>{error["reason"]}</div>
                ))
              ) : (
                <div id={1}>
                  There was a problem creating your main summary request. Please
                  try again later.
                </div>
              )}
            </Alert>
          )}
        </Snackbar>
      </Fragment>
    </Statuses>
  );
}

export default DashboardView;
