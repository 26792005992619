import React, { useState } from "react";
import { ApiProvider } from "../../../japi";
import { ContextDataTable } from "../../../shared/components/ContextDataTable";
import Actions from "../components/Actions";
import AddIcon from "@material-ui/icons/Add";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core";
import DeleteDialog from "../components/DeleteDialog";
import Snackbar from "../components/SnackbarCustom";
import { useAuth0 } from "../../../react-auth0-spa";
import { format } from "date-fns";
import ManualAllocationDialog from "./components/ManualAllocationDialog";

const useStyles = makeStyles(theme => ({
  extendedIcon: {
    marginRight: theme.spacing(0.5)
  },
  categoryButton: {
    marginBottom: "0.5rem"
  }
}));

function ManualEntityAllocation() {
  const classes = useStyles();
  const { user } = useAuth0();

  const columns = [
    {
      name: "ssid",
      label: "SSID",
      options: {
        filter: true,
        sort: false,
        filterType: "textField"
      },
      formOptions: {
        editable: true,
        disabled: true,
        required: true
      }
    },
    {
      name: "brand",
      label: "Brand",
      options: {
        filter: true,
        sort: false,
        filterType: "textField"
      },
      formOptions: {
        editable: true,
        required: true
      }
    },
    {
      name: "entity",
      label: "Entity",
      options: {
        filter: true,
        sort: false,
        filterType: "textField"
      },
      formOptions: {
        editable: true,
        required: true,
        disabled: true
      }
    },
    {
      name: "resellerAcct",
      label: "Reseller Account",
      options: {
        filter: true,
        sort: false,
        filterType: "textField"
      },
      formOptions: {
        editable: true,
        required: true
      }
    },
    {
      name: "dateAdded",
      label: "Created date",
      options: {
        filter: true,
        filterType: "textField",
        sort: false
      },
      formOptions: {
        hidden: true,
        defaultValue: format(new Date(), "yyyy-MM-dd")
      }
    },
    {
      name: "username",
      label: "Created by",
      options: {
        filter: true,
        filterType: "textField",
        sort: false
      },
      formOptions: {
        hidden: true,
        defaultValue: user.name
      }
    },
    {
      name: "",
      label: "Actions",
      options: {
        customBodyRender: (value, data) => {
          const { rowData } = data;

          if (!Array.isArray(rowData[0])) {
            return (
              <Actions
                rowData={rowData}
                onRowAction={onRowAction}
                setDeleteDialogOpen={setDeleteDialogOpen}
                setEditDialogOpen={setEditDialogOpen}
              />
            );
          }
        },
        sort: false,
        filter: false
      }
    },
    {
      name: "brandToEntity",
      label: "Brand to entity",
      options: {
        display: "excluded",
        sort: false,
        filter: false
      }
    }
  ];

  const [lastUpdated, setLastUpdated] = useState();
  const [createDialogOpen, setCreateDialogOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [id, setId] = useState();
  const [rowData, setRowData] = useState([]);

  const [snackbarMessage, setSnackbarMessage] = useState();
  const [snackbarSuccess, setSnackbarSuccess] = useState(true);
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const handleDataChange = () => {
    setLastUpdated(Date.now());
  };

  const handleCreateClick = () => {
    setCreateDialogOpen(true);
  };

  const handleClose = () => {
    setCreateDialogOpen(false);
    setDeleteDialogOpen(false);
    setEditDialogOpen(false);
  };

  const onRowAction = row => {
    setId(row[0]);
    setRowData(row);
  };

  const updateSnackbar = (success, message) => {
    setSnackbarSuccess(success);
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };

  return (
    <React.Fragment>
      <Snackbar
        snackbarMessage={snackbarMessage}
        snackbarSuccess={snackbarSuccess}
        snackbarOpen={snackbarOpen}
        setSnackbarOpen={setSnackbarOpen}
      />

      <Button
        className={classes.categoryButton}
        onClick={handleCreateClick}
        variant="contained"
        color={"primary"}
        startIcon={<AddIcon>className={classes.extendedIcon}</AddIcon>}
        align={"right"}
      >
        Add row
      </Button>
      <ApiProvider
        initialSortField="dateAdded"
        initialEndpoint="manual-entity-allocation"
        lastUpdated={lastUpdated}
      >
        <ContextDataTable columns={columns} />
      </ApiProvider>

      <DeleteDialog
        open={deleteDialogOpen}
        endpoint={"manual-entity-allocation"}
        handleClose={handleClose}
        onSuccess={handleDataChange}
        updateSnackbar={updateSnackbar}
        id={id}
      />

      <ManualAllocationDialog
        updateSnackbar={updateSnackbar}
        onClose={handleClose}
        onSuccess={handleDataChange}
        open={createDialogOpen || editDialogOpen}
        rowData={rowData}
        edit={editDialogOpen}
        id={id}
      />
    </React.Fragment>
  );
}

export default ManualEntityAllocation;
