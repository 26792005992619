import React from "react";
import PropTypes from "prop-types";
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  root: {
    width: "32%",
    height: 90,
    textTransform: "uppercase"
  },
  icon: {
    "& > svg": {
      fontSize: 58
    }
  }
}));

function InfoCard({ value, label }) {
  const classes = useStyles();
  return (
    <Card variant="outlined" className={classes.root}>
      <Box display="flex" px={2} height="100%">
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          py={2}
        >
          <Box fontSize={24} fontWeight={700} color="info.main">
            {value}
          </Box>
          {label}
        </Box>
      </Box>
    </Card>
  );
}

InfoCard.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired
};

export default InfoCard;
