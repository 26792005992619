import axios from "axios";
import { API_PLATFORM_BASE_URL } from "../config";
import { getTokenSilently } from "../react-auth0-spa";

const javaApiInstance = axios.create({
  baseURL: API_PLATFORM_BASE_URL
});

javaApiInstance.interceptors.request.use(async config => {
  try {
    const token = await getTokenSilently();
    if (token) {
      config.headers["Authorization"] = "Bearer " + token;
      return config;
    }
  } catch (error) {
    Promise.reject(error);
  }
});

export default javaApiInstance;
