import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  submitButton: {
    color: theme.palette.success.contrastText,
    backgroundColor: theme.palette.success.main,
    "&:hover": {
      backgroundColor: theme.palette.success.dark
    }
  }
}));

function OverrideDialog({
  open,
  onClose,
  openAllocationDialog,
  handleOpenSsidDialog,
  handleOpenAllocationDialog
}) {
  const handleConfirmation = () => {
    onClose();

    if (openAllocationDialog) {
      handleOpenAllocationDialog();
    } else {
      handleOpenSsidDialog();
    }
  };

  const classes = useStyles();

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {"Proceed with action?"}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          You have selected records populated with data. Are you sure you want
          to proceed with your action?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button className={classes.submitButton} onClick={handleConfirmation}>
          Proceed
        </Button>
        <Button onClick={onClose} autoFocus>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default OverrideDialog;
