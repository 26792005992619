export const RECONCILIATION_STATUS_REQUESTED =
  "RECONCILIATION_STATUS_REQUESTED";
export const RECONCILIATION_STATUS_SUCCESS = "RECONCILIATION_STATUS_SUCCESS";
export const RECONCILIATION_STATUS_ERROR = "RECONCILIATION_STATUS_ERROR";

export function fetchReconciliationStatusSuccess(payload) {
  return {
    type: RECONCILIATION_STATUS_SUCCESS,
    payload
  };
}
