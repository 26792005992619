import React from "react";
import Fab from "@material-ui/core/Fab";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  root: {
    color: theme.palette.success.contrastText,
    backgroundColor: theme.palette.success.main,
    "&:hover": {
      backgroundColor: theme.palette.success.dark
    },
    position: "fixed",
    bottom: theme.spacing(5),
    right: theme.spacing(5),
    textTransform: "uppercase",
    zIndex: 9999
  }
}));

function FixedFab(props) {
  const classes = useStyles();
  return (
    <Fab
      variant="extended"
      {...props}
      className={classes.root + (props.className ? ` ${props.className}` : "")}
    >
      {props.children}
    </Fab>
  );
}

export default FixedFab;
