import React from "react";
import { Box } from "@material-ui/core";

import daisyLogo from "../assets/daisy_logo.png";
import User from "./User";

function TopBar() {
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      width={1}
    >
      <Box display="flex" alignItems="center">
        <img
          src={daisyLogo}
          alt="daisy logo"
          style={{ height: "44px", width: "44px" }}
        />
        <Box ml={1} fontSize={18}>
          Reconciliation
        </Box>
      </Box>
      <User />
    </Box>
  );
}

export default TopBar;
