import { call, put, takeLatest } from "redux-saga/effects";
import { getReconciliationStatus } from "../api";
import {
  fetchReconciliationStatusSuccess,
  RECONCILIATION_STATUS_ERROR,
  RECONCILIATION_STATUS_REQUESTED
} from "../actions";

function* fetchReconciliationStatus() {
  try {
    const data = yield call(getReconciliationStatus);
    yield put(fetchReconciliationStatusSuccess(data));
  } catch (e) {
    yield put({ type: RECONCILIATION_STATUS_ERROR });
  }
}

export default [
  takeLatest(RECONCILIATION_STATUS_REQUESTED, fetchReconciliationStatus)
];
