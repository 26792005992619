import React, { useState } from "react";
import PropTypes from "prop-types";

import { createReportRequest } from "../api";

import { makeStyles } from "@material-ui/core";
import {
  generateRunMonthsArray,
  formatMonth
} from "../../../shared/helpers/getRunMonths";
import { generateRunYearsArray } from "../../../shared/helpers/getRunYears";
import getReportTypes from "../helpers/getReportTypes";
import getSuppliers from "../helpers/getSuppliers";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import DialogContentText from "@material-ui/core/DialogContentText";

const useStyles = makeStyles(theme => ({
  gridContainer: {
    paddingBottom: theme.spacing(1)
  },
  submitButton: {
    color: theme.palette.success.contrastText,
    backgroundColor: theme.palette.success.main,
    "&:hover": {
      backgroundColor: theme.palette.success.dark
    }
  }
}));

function CreateDialog({ open, onClose, onCreateFailure, onCreateSuccess }) {
  const [year, setYear] = useState(new Date().getFullYear());
  const [month, setMonth] = useState(formatMonth(new Date().getMonth()));
  const [report, setReport] = useState("");
  const [supplier, setSupplier] = useState("");
  const [supplierEnabled, setSupplierEnabled] = useState(false);

  function handleClose() {
    onClose();
  }

  function updateSupplierVisibility(reportType) {
    if (reportType === "SUPPLIER_CONSISTENCY_CHECK_REPORT") {
      setSupplierEnabled(true);
    } else {
      setSupplierEnabled(false);
    }
  }

  function isValid() {
    if (report === "SUPPLIER_CONSISTENCY_CHECK_REPORT") {
      return report !== "" && supplier !== "" && year !== "" && month !== "";
    } else {
      return report !== "" && year !== "" && month !== "";
    }
  }

  async function handleSubmit() {
    try {
      let values = {
        reportType: report,
        supplier: supplier,
        month: month,
        year: year
      };
      await createReportRequest(values);
      onCreateSuccess();
    } catch (e) {
      onCreateFailure(e.response !== undefined ? e.response.data.errors : []);
    }
    handleClose();
  }

  const classes = useStyles();

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="create-schedule-form"
    >
      <DialogTitle id="supplier-files-upload-form" disableTypography>
        <Box component="h2" fontSize="1.5rem" fontWeight="400" m={0}>
          Request Report
        </Box>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          Please select the report type, year, month and any remaining
          parameters required for the report you wish to receive.
        </DialogContentText>
        <DialogContentText>
          Once complete, please tap 'Submit'.
        </DialogContentText>
      </DialogContent>
      <form>
        <DialogContent>
          <Box pt={1}>
            <Grid
              container
              spacing={2}
              classes={{ container: classes.gridContainer }}
            >
              <Grid item xs={12}>
                <FormControl fullWidth size="small" variant="outlined">
                  <Select
                    name="reportType"
                    value={report}
                    displayEmpty
                    inputProps={{ "aria-label": "Without label" }}
                    onChange={e => {
                      setReport(e.target.value);
                      updateSupplierVisibility(e.target.value);
                    }}
                  >
                    <MenuItem value={""} disabled>
                      Select Report
                    </MenuItem>
                    {getReportTypes.map((type, i) => (
                      <MenuItem key={i} value={type}>
                        {type}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl
                  fullWidth
                  label={"Run Year"}
                  size="small"
                  variant="outlined"
                >
                  <Select
                    name="year"
                    value={year}
                    onChange={e => setYear(e.target.value)}
                  >
                    <MenuItem value={""} disabled>
                      Select year
                    </MenuItem>
                    {generateRunYearsArray().map((year, i) => (
                      <MenuItem key={i} value={year}>
                        {year}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl fullWidth size="small" variant="outlined">
                  <Select
                    name="month"
                    value={month}
                    onChange={e => setMonth(e.target.value)}
                  >
                    <MenuItem value={""} disabled>
                      Select month
                    </MenuItem>
                    {generateRunMonthsArray().map((month, i) => (
                      <MenuItem key={i} value={month}>
                        {month}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              {supplierEnabled && (
                <Grid item xs={12}>
                  <FormControl fullWidth size="small" variant="outlined">
                    <Select
                      name={"supplier"}
                      displayEmpty
                      inputProps={{ "aria-label": "Without label" }}
                      onChange={e => setSupplier(e.target.value)}
                      value={supplier}
                      disabled={!supplierEnabled}
                    >
                      <MenuItem value={""} disabled>
                        Select Supplier
                      </MenuItem>
                      {getSuppliers.map((supplier, i) => (
                        <MenuItem key={i} value={supplier}>
                          {supplier}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              )}
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            className={classes.submitButton}
            size="large"
            variant="contained"
            onClick={handleSubmit}
            disabled={!isValid()}
          >
            Submit
          </Button>
          <Button onClick={handleClose} size="large" variant="text">
            Cancel
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

CreateDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onCreateFailure: PropTypes.func.isRequired,
  onCreateSuccess: PropTypes.func.isRequired
};

export default CreateDialog;
