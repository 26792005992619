import javaApiInstance from "../../../api/javaApiInstance";

export async function deleteEntity(id, endpoint) {
  let success;

  try {
    await javaApiInstance.delete(`${endpoint}/${id}`).then(
      response => {
        if (response.status === 204 || response.status === 200) {
          success = true;
        } else {
          success = false;
        }
      },
      () => {
        success = false;
      }
    );
  } catch (e) {
    success = false;
  }

  return success;
}

export async function updateEntity(id, endpoint, data) {
  let status = {
    success: false,
    errors: []
  };

  try {
    await javaApiInstance.patch(`${endpoint}/${id}`, data).then(response => {
      if (response.status === 204 || response.status === 200) {
        status.success = true;
        status.message = "Record was updated.";
      } else {
        status.success = false;
        status.message =
          "Could not update record. Please try later or contact administrator.";
      }
    });
  } catch (error) {
    status.success = false;

    if (error.response.status === 400) {
      status.errors = error.response.data.errors;
    }

    status.message =
      "Could not update record. Please try later or contact administrator.";
  }

  return status;
}

export async function createEntity(endpoint, data) {
  let status = {
    success: false,
    errors: []
  };

  try {
    await javaApiInstance.post(`${endpoint}`, data).then(response => {
      if (
        response.status === 204 ||
        response.status === 200 ||
        response.status === 201
      ) {
        status.success = true;
        status.message = "Record was created.";
      } else {
        status.success = false;
        status.message =
          "Could not create record. Please try later or contact administrator.";
      }
    });
  } catch (error) {
    status.success = false;

    if (error.response.status === 400) {
      status.errors = error.response.data.errors;
    }

    status.message =
      "Could not create record. Please try later or contact administrator.";
  }

  return status;
}
