import javaApi from "../../../api/javaApiInstance";
import defaultAxios from "axios";
import { format } from "date-fns";

export function fetchSupplierFiles(date) {
  const params = {};

  if (date instanceof Date) {
    params.year = format(date, "yyyy");
    params.month = format(date, "MM");
  }

  return javaApi
    .request({
      url: "supplier/daisy/summary",
      params
    })
    .then(({ data }) => data);
}

export async function uploadFile(year, month, file, supplierCode) {
  return new Promise(async function(resolve, reject) {
    try {
      const res = await javaApi.post("supplier-upload-url", {
        year,
        month,
        filename: file.name,
        supplierCode
      });
      const signedUrl = res.data.signedUrl;

      // use the default axios client instead of our instance (our instance has eg. baseUrl and auth headers included)
      try {
        await defaultAxios.put(signedUrl, file, {
          headers: { "Content-Type": "text/csv" }
        });
      } catch (e) {
        throw e;
      }
      resolve();
    } catch (e) {
      reject(e);
    }
  });
}
