import React from "react";
import TableContainer from "@material-ui/core/TableContainer";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  incorrectTableRow: {
    backgroundColor: "rgba(239,16,16,0.5)"
  },
  correctTableRow: {
    backgroundColor: "rgba(72,180,80,0.5)"
  },
  table: {
    minWidth: 380
  }
}));

function SupplierCheckPopup({
  supplierCheck,
  isImportIncorrect,
  isMiDataIncorrect,
  isBeforeReconciliation
}) {
  const classes = useStyles();

  const getMiDataStyle = () => {
    if (isBeforeReconciliation) {
      return null;
    } else if (isMiDataIncorrect) {
      return classes.incorrectTableRow;
    }

    return classes.correctTableRow;
  };

  return (
    supplierCheck && (
      <TableContainer>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell>Source</TableCell>
              <TableCell align="right">Count</TableCell>
              <TableCell align="right">Total</TableCell>
              <TableCell align="right">Zero rated</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell component="th" scope="row">
                Supplier file
              </TableCell>
              <TableCell align="right">
                {supplierCheck.supplierSummaryCount}
              </TableCell>
              <TableCell align="right">
                {supplierCheck.supplierSummaryTotal}
              </TableCell>
              <TableCell align="right">
                {supplierCheck.zeroRatedCount
                  ? supplierCheck.zeroRatedCount
                  : 0}
              </TableCell>
            </TableRow>
            <TableRow
              className={
                isImportIncorrect
                  ? classes.incorrectTableRow
                  : classes.correctTableRow
              }
            >
              <TableCell component="th" scope="row">
                Imported rows
              </TableCell>
              <TableCell align="right">
                {supplierCheck.supplierDataCount}
              </TableCell>
              <TableCell align="right">
                {supplierCheck.supplierDataTotal}
              </TableCell>
              <TableCell align="right"></TableCell>
            </TableRow>
            <TableRow className={getMiDataStyle()}>
              <TableCell component="th" scope="row">
                MiData
              </TableCell>
              <TableCell align="right">{supplierCheck.miDataCount}</TableCell>
              <TableCell align="right">{supplierCheck.miDataTotal}</TableCell>
              <TableCell align="right"></TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    )
  );
}

export default SupplierCheckPopup;
