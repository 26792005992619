import React from "react";
import PropTypes from "prop-types";
import CountUp from "react-countup";
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  root: {
    width: "19%",
    height: 90,
    textTransform: "uppercase"
  },
  icon: {
    "& > svg": {
      fontSize: 58
    }
  }
}));

function SummaryCard({
  prefix,
  decimals,
  value,
  label,
  animateDuration = 0.3
}) {
  const classes = useStyles();
  return (
    <Card variant="outlined" className={classes.root}>
      <Box display="flex" px={2} height="100%">
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          py={2}
        >
          <Box fontSize={24} fontWeight={700} color="info.main">
            <CountUp
              end={value}
              duration={animateDuration}
              useEasing={false}
              preserveValue={true}
              separator=","
              decimals={decimals}
              prefix={prefix}
            />
          </Box>
          {label}
        </Box>
      </Box>
    </Card>
  );
}

SummaryCard.propTypes = {
  animateDuration: PropTypes.number,
  label: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired
};

export default SummaryCard;
