import React from "react";
import ReactDOM from "react-dom";
import App from "./App";

import { AUTH_AUDIENCE, AUTH_CLIENT_ID, AUTH_DOMAIN } from "./config";

import CssBaseline from "@material-ui/core/CssBaseline";
import { ThemeProvider } from "@material-ui/core/styles";
import theme from "./theme";

import { Provider } from "react-redux";
import configureStore from "./store/configureStore";
import rootSaga from "./store/rootSaga";

import { Router } from "react-router-dom";
import history from "./util/history";

import { Auth0Provider } from "./react-auth0-spa";

// A function that routes the user to the right place
// after login
const onRedirectCallback = appState => {
  history.push(
    appState && appState.targetUrl
      ? appState.targetUrl
      : window.location.pathname
  );
};

const store = configureStore();
store.runSaga(rootSaga);

ReactDOM.render(
  <Auth0Provider
    domain={AUTH_DOMAIN}
    client_id={AUTH_CLIENT_ID}
    redirect_uri={window.location.origin}
    onRedirectCallback={onRedirectCallback}
    audience={AUTH_AUDIENCE}
  >
    <Provider store={store}>
      <Router history={history}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <App />
        </ThemeProvider>
      </Router>
    </Provider>
  </Auth0Provider>,
  document.getElementById("root")
);
