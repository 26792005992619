import React from "react";

import { Redirect, Switch } from "react-router-dom";
import Route from "./shared/components/PrivateRoute";

import Layout from "./layout";
import Main from "./layout/Main";
import Reports from "./features/Reports";
import Schedules from "./features/Schedules";
import SupplierData from "./features/SupplierData";
import Dashboard from "./features/Dashboard";
import Statuses from "./shared/components/Statuses";
import SupplierFiles from "./features/SupplierFiles";

import { useAuth0 } from "./react-auth0-spa";
import MiData from "./features/MiData";
import MainSummary from "./features/MainSummary";
import SideBarMain from "./layout/main/SideBarMain";
import SideBarSettings from "./layout/settings/SideBarSettings";
import ManualEntityAllocation from "./features/Settings/ManualEntityAllocation";
import Kpi from "./features/Settings/Kpi";
import BrandToEntity from "./features/Settings/BrandToEntity";

function App() {
  const { loading } = useAuth0();

  if (loading) {
    return (
      <Layout>
        <Statuses loading />
      </Layout>
    );
  }

  return (
    <Switch>
      <Route path={["/manual-entity-allocation", "/kpi", "/brand-to-entity"]}>
        <Layout sidebar={<SideBarSettings />}>
          <Switch>
            <Route path="/manual-entity-allocation">
              <Main title={"Manual entity allocation"}>
                <ManualEntityAllocation />
              </Main>
            </Route>
            <Route path="/kpi">
              <Main title={"KPI"}>
                <Kpi />
              </Main>
            </Route>
            <Route path="/brand-to-entity">
              <Main title={"Brand to entity"}>
                <BrandToEntity />
              </Main>
            </Route>
          </Switch>
        </Layout>
      </Route>

      <Route
        path={[
          "/dashboard",
          "/reports",
          "/schedules",
          "/supplier-data",
          "/supplier-files",
          "/mi-data",
          "/main-summary",
          "*"
        ]}
      >
        <Layout sidebar={<SideBarMain />}>
          <Switch>
            <Route path="/dashboard">
              <Main title={"Dashboard"}>
                <Dashboard />
              </Main>
            </Route>
            <Route path="/reports">
              <Main title="Reports">
                <Reports />
              </Main>
            </Route>
            <Route path="/schedules">
              <Main title="Schedules">
                <Schedules />
              </Main>
            </Route>
            <Route path="/supplier-data">
              <Main title="Supplier Data Search">
                <SupplierData />
              </Main>
            </Route>
            <Route path="/supplier-files">
              <Main title="Supplier Files">
                <SupplierFiles />
              </Main>
            </Route>
            <Route path="/mi-data">
              <Main title="MI Data Search">
                <MiData />
              </Main>
            </Route>
            <Route path="/main-summary">
              <Main title="Main Summary Data Search">
                <MainSummary />
              </Main>
            </Route>
            <Route path="*">
              {/* Handle unmatched routes */}
              <Redirect to="/dashboard" />
            </Route>
          </Switch>
        </Layout>
      </Route>
    </Switch>
  );
}

export default App;
