import React from "react";
import PropTypes from "prop-types";
import { format as formatDate } from "date-fns";
import CountUp from "react-countup";

import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import SummaryCard from "./SummaryCard";

function Dashboard({ onReset, selectedIds, data, selectedDate }) {
  const ANIMATE_DURATION = 0.3;

  function totalSelectedByKey(key) {
    const selected = data.filter(file => selectedIds.includes(file.id));
    return selected.reduce((acc, curr) => {
      return acc + curr[key];
    }, 0);
  }

  return (
    <React.Fragment>
      <Box
        pb={3}
        display="flex"
        justifyContent="space-between"
        color="info.main"
      >
        <Box fontSize="3rem">
          Total{" "}
          <Box component="strong" fontWeight="700">
            £
            <CountUp
              end={totalSelectedByKey("netTotal")}
              duration={ANIMATE_DURATION}
              useEasing={false}
              preserveValue={true}
              separator=","
              decimals={2}
            />
          </Box>
        </Box>
        <Box fontSize="1.125rem" display="flex" flexDirection="column">
          <Box color="text.primary">
            {selectedDate && formatDate(selectedDate, "MMM y").toUpperCase()}
          </Box>
          <Button onClick={onReset} size="large" color="inherit">
            Reset
          </Button>
        </Box>
      </Box>
      <Box display="flex" justifyContent="space-between">
        <SummaryCard
          animateDuration={ANIMATE_DURATION}
          value={totalSelectedByKey("identifiers")}
          label="Total Identifiers"
        />
        <SummaryCard
          animateDuration={ANIMATE_DURATION}
          value={totalSelectedByKey("rentalCount")}
          label="Rentals"
        />
        <SummaryCard
          animateDuration={ANIMATE_DURATION}
          prefix={"£"}
          value={totalSelectedByKey("rentalValue")}
          label="Rental Total"
          decimals={2}
        />
        <SummaryCard
          animateDuration={ANIMATE_DURATION}
          value={totalSelectedByKey("oneOffCount")}
          label="One Offs"
        />
        <SummaryCard
          animateDuration={ANIMATE_DURATION}
          prefix={"£"}
          value={totalSelectedByKey("oneOffValue")}
          label="One Off Total"
          decimals={2}
        />
      </Box>
    </React.Fragment>
  );
}

Dashboard.propTypes = {
  onReset: PropTypes.func.isRequired,
  selectedIds: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      identifiers: PropTypes.number.isRequired,
      rentalCount: PropTypes.number.isRequired,
      rentalValue: PropTypes.number.isRequired,
      oneOffCount: PropTypes.number.isRequired,
      oneOffValue: PropTypes.number.isRequired
    }).isRequired
  ).isRequired
};

export default Dashboard;
