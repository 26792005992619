import React, { useState } from "react";
import PropTypes from "prop-types";

import { makeStyles } from "@material-ui/core";

import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";

import { updateSsidAndCli } from "../api";
import FormControl from "@material-ui/core/FormControl";
import DialogContentText from "@material-ui/core/DialogContentText";
import TextField from "@material-ui/core/TextField";

const useStyles = makeStyles(theme => ({
  gridContainer: {
    paddingBottom: theme.spacing(1)
  },
  submitButton: {
    color: theme.palette.success.contrastText,
    backgroundColor: theme.palette.success.main,
    "&:hover": {
      backgroundColor: theme.palette.success.dark
    }
  }
}));

function AllocateSSIDDialog({
  open,
  onClose,
  selectedRows,
  onUpdateSuccess,
  onUpdateFailure
}) {
  const [ssid, setSsid] = useState(null);
  const [cli, setCli] = useState(null);

  //Validate form input
  function isValid() {
    return selectedRows.length > 0 && (cli || ssid);
  }

  const clearData = () => {
    setSsid(null);
    setCli(null);
  };

  function handleClose() {
    onClose();
    clearData();
  }

  function handleCliChange(e) {
    setCli(e.target.value);
  }

  function handleSsidChange(e) {
    setSsid(e.target.value);
  }

  async function handleSubmit() {
    let ssidList = [];

    //Build request list
    for (const row of selectedRows) {
      let data = {
        miDataId: row.id,
        ssid: ssid,
        cli: cli
      };

      ssidList.push(data);
    }

    //Invoke requests and check for any failures, and relay to UI
    let response = await updateSsidAndCli(ssidList);

    if (response) {
      onUpdateSuccess("Record was updated.");
    } else {
      onUpdateFailure([
        "There has been an error updating your record(s), please review and retry later."
      ]);
    }

    handleClose();
  }

  const classes = useStyles();

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle disableTypography>
        <Box component="h2" fontSize="1.5rem" fontWeight="400" m={0} pb={5}>
          Allocate SSID
        </Box>
        <Box component="h3" fontSize="1rem" fontWeight="500" m={0}>
          Selected IDs: {selectedRows.length}
        </Box>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          Please provide SSID and CLI, review your selection and click submit to
          update these fields.
        </DialogContentText>
      </DialogContent>
      <form>
        <DialogContent>
          <Box pt={1}>
            <Grid
              container
              spacing={2}
              classes={{ container: classes.gridContainer }}
            >
              <Grid item xs={12}>
                <FormControl fullWidth label={"SSID"} variant="outlined">
                  <TextField
                    label={"SSID"}
                    name="ssid"
                    onChange={handleSsidChange}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12}>
                <FormControl fullWidth label={"CLI"} variant="outlined">
                  <TextField
                    label={"CLI"}
                    name="cli"
                    onChange={handleCliChange}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            className={classes.submitButton}
            size="large"
            variant="contained"
            onClick={handleSubmit}
            disabled={!isValid()}
          >
            Submit
          </Button>
          <Button onClick={handleClose} size="large" variant="text">
            Cancel
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

AllocateSSIDDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  selectedRows: PropTypes.array.isRequired,
  onUpdateSuccess: PropTypes.func.isRequired,
  onUpdateFailure: PropTypes.func.isRequired
};

export default AllocateSSIDDialog;
