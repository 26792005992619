import React from "react";
import DataTable from "../../../shared/components/DataTable";
import moment from "moment";
import { formatDuration } from "../../../shared/helpers/duration";
import { CircularProgress } from "@material-ui/core";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ErrorIcon from "@material-ui/icons/Error";
import PropTypes from "prop-types";

function ReconciliationStatus({ steps }) {
  return (
    <DataTable
      dateTimeSelectors={["timestamp"]}
      defaultSortSelector={"timestamp"}
      colorReverse
      title="Latest reconciliation progress"
      data={prepareData(steps)}
      columns={[
        { title: "Step", selector: "step" },
        { title: "Runtime", selector: "currentTime" },
        { title: "Average time", selector: "averageDuration" },
        { title: "Status", selector: "status" }
      ]}
      DataTable
    />
  );
}

function prepareData(steps) {
  if (steps && steps.length > 0) {
    return steps.map(step => {
      return {
        id: step.description,
        step: stepsDescription[step.description]
          ? stepsDescription[step.description]
          : step.description,
        status: getStatusLabel(step.status),
        averageDuration: formatDuration(
          moment.duration(step.averageDuration, "seconds")
        ),
        currentTime: formatDuration(
          moment.duration(step.executionTime, "seconds")
        )
      };
    });
  } else {
    return [];
  }
}

const getStatusLabel = status => {
  if (status === "STARTED" || status === "STARTING") {
    return <CircularProgress style={{ color: "orange" }} size={23} />;
  } else if (status === "COMPLETED") {
    return <CheckCircleIcon style={{ color: "green" }} />;
  } else {
    return <ErrorIcon color="error" />;
  }
};

const stepsDescription = {
  "check-replication-status": "Verification of data replication status",
  "categorize-mpf-voice-only-broadband": "Categorizing MPF Voice Only",
  "migrate-evo-current-month": "Migrating EVO data for current month",
  "generate-supplier-ssid-summary": "Processing supplier files",
  "generate-bli": "Processing DC data",
  "generate-pli": "Processing EVO data",
  "generate-evo-summary": "Summarizing EVO data",
  "generate-data-for-main-summary": "Preparing data for main summary",
  "update-bill-cli-history": "Updating billing CLI history",
  "update-service-history-bli": "Updating DC service history",
  "insert-data-in-main-summary": "Storing data in main summary",
  "update-service-identifier-history": "Updating SSID service history",
  "update-service-history-pli": "Updating EVO service history",
  "update-MiData": "Storing MI Data"
};

ReconciliationStatus.propTypes = {
  steps: PropTypes.array.isRequired
};

export default ReconciliationStatus;
