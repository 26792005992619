import { combineReducers } from "redux";
import app from "../shared/reducers";
import supplierFiles from "../features/SupplierFiles/reducers";
import chargeInfo from "../features/SupplierData/reducers";
import schedules from "../features/Schedules/reducers";
import dashboard from "../features/Dashboard/reducers";

export default combineReducers({
  app,
  supplierFiles,
  chargeInfo,
  schedules,
  dashboard
});
