import React, { Fragment, useCallback, useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
  TextField
} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
import Box from "@material-ui/core/Box";
import { updateEntity } from "../api";

const useStyles = makeStyles(theme => ({
  submitButton: {
    color: theme.palette.success.contrastText,
    backgroundColor: theme.palette.warning.main,
    "&:hover": {
      backgroundColor: theme.palette.warning.dark
    }
  }
}));

function EditDialog({
  open,
  handleClose,
  endpoint,
  onSuccess,
  id,
  form,
  formData,
  updateSnackbar
}) {
  const classes = useStyles();

  const initFieldStatues = () => {
    let initFieldStatues = {};

    form.forEach(inputField => {
      const { formOptions } = inputField;

      if (formOptions) {
        if (!formOptions.hidden) {
          initFieldStatues[inputField.name] = {
            error: false,
            errorHelperText: ""
          };
        }
      }
    });

    return initFieldStatues;
  };

  const initForm = useCallback(() => {
    const initFormValues = {};

    form.forEach((inputField, i) => {
      const { formOptions } = inputField;

      if (formOptions) {
        if (formOptions.hidden) {
          initFormValues[inputField.name] = formOptions.defaultValue;
        } else {
          initFormValues[inputField.name] = formData[i] ? formData[i] : "";
        }
      }
    });

    return initFormValues;
  }, [form, formData]);

  useEffect(() => {
    setFormValues(initForm());
  }, [formData, initForm]);

  const [formValues, setFormValues] = useState(() => initForm());
  const [fieldStatuses, setFiledStatuses] = useState(() => initFieldStatues());

  const handleEdit = async () => {
    let response = await updateEntity(id, endpoint, formValues);

    if (response.success) {
      updateSnackbar(true, response.message);
      onSuccess();
      onDialogClose();
    } else if (response.errors.length > 0) {
      updateFormErrors(response.errors);
    } else {
      updateSnackbar(false, response.message);
      onDialogClose();
    }
  };

  const clearForm = () => {
    setFormValues(initForm());
    setFiledStatuses(initFieldStatues());
  };

  const onDialogClose = () => {
    handleClose();
    clearForm();
  };

  const updateFormErrors = errors => {
    let updatedFieldStatuses = initFieldStatues();

    errors.forEach(error => {
      updatedFieldStatuses[error.property] = {
        error: true,
        errorHelperText: error.message ? error.message : error.defaultMessage
      };
    });

    setFiledStatuses(updatedFieldStatuses);
  };

  function handleChange(event) {
    let updatedForm = { ...formValues };
    updatedForm[this] = event.target.value;
    setFormValues(updatedForm);
  }

  const generateForm = () => {
    return Object.keys(formValues)
      .filter((key, i) => !form[i].formOptions.hidden)
      .map((key, i) => {
        return (
          <Grid item xs={12} key={`grid-${key}`}>
            <FormControl
              key={`from-control-${key}`}
              fullWidth
              label={form[i].label}
              variant="outlined"
            >
              <TextField
                disabled={form[i].formOptions.disabled}
                key={`text-field-${key}`}
                label={form[i].label}
                name={key}
                onChange={handleChange.bind(key)}
                value={formValues[key]}
                error={fieldStatuses[key].error}
                helperText={fieldStatuses[key].errorHelperText}
                required={form[i].formOptions.required}
              />
            </FormControl>
          </Grid>
        );
      });
  };

  return (
    <Fragment>
      <Dialog open={open}>
        <DialogTitle>Edit row</DialogTitle>
        <DialogContent>
          <Box pt={1}>
            <Grid
              container
              spacing={2}
              classes={{ container: classes.gridContainer }}
            >
              {generateForm()}
            </Grid>
          </Box>
        </DialogContent>

        <DialogActions>
          <Button
            type={"submit"}
            onClick={handleEdit}
            size="large"
            variant="contained"
            className={classes.submitButton}
          >
            Yes
          </Button>

          <Button onClick={onDialogClose} size="large" variant="contained">
            No
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
}

export default EditDialog;
