import { all } from "redux-saga/effects";

import appSagas from "../shared/sagas";
import supplierFilesSagas from "../features/SupplierFiles/sagas";
import schedulesSagas from "../features/Schedules/sagas";
import chargeInfoSagas from "../features/SupplierData/sagas";
import dashboardSagas from "../features/Dashboard/sagas";

export default function* rootSaga() {
  yield all([
    ...appSagas,
    ...supplierFilesSagas,
    ...chargeInfoSagas,
    ...schedulesSagas,
    ...dashboardSagas
  ]);
}
