import React, { useState } from "react";
import { ApiProvider } from "../../../japi";
import { ContextDataTable } from "../../../shared/components/ContextDataTable";
import Actions from "../components/Actions";
import AddIcon from "@material-ui/icons/Add";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core";
import CreateDialog from "../components/CreateDialog";
import DeleteDialog from "../components/DeleteDialog";
import EditDialog from "../components/EditDialog";
import Snackbar from "../components/SnackbarCustom";

const useStyles = makeStyles(theme => ({
  extendedIcon: {
    marginRight: theme.spacing(0.5)
  },
  categoryButton: {
    marginBottom: "0.5rem"
  }
}));

function Kpi() {
  const classes = useStyles();

  const columns = [
    {
      name: "techSpeed",
      label: "Tech speed",
      options: {
        filter: true,
        sort: false,
        filterType: "textField"
      },
      formOptions: {
        editable: true,
        required: true
      }
    },
    {
      name: "kpi",
      label: "KPI",
      options: {
        filter: true,
        sort: false,
        filterType: "textField"
      },
      formOptions: {
        editable: true,
        required: true
      }
    },
    {
      name: "costCentre",
      label: "Cost centre",
      options: {
        filter: true,
        sort: false,
        filterType: "textField"
      },
      formOptions: {
        editable: true,
        required: true
      }
    },
    {
      name: "",
      label: "Actions",
      options: {
        customBodyRender: (value, data) => {
          const { rowData } = data;

          //disable if spinner is active
          if (!Array.isArray(rowData[0])) {
            return (
              <Actions
                rowData={rowData}
                onRowAction={onRowAction}
                setDeleteDialogOpen={setDeleteDialogOpen}
                setEditDialogOpen={setEditDialogOpen}
              />
            );
          }
        },
        sort: false,
        filter: false
      }
    },
    {
      name: "id",
      label: "ID",
      options: {
        sort: false,
        filter: false,
        display: false
      }
    }
  ];

  const [lastUpdated, setLastUpdated] = useState();
  const [createDialogOpen, setCreateDialogOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [id, setId] = useState();
  const [rowData, setRowData] = useState([]);

  const [snackbarMessage, setSnackbarMessage] = useState();
  const [snackbarSuccess, setSnackbarSuccess] = useState(true);
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const handleDataChange = () => {
    setLastUpdated(Date.now());
  };

  const handleCreateClick = () => {
    setCreateDialogOpen(true);
  };

  const handleClose = () => {
    setCreateDialogOpen(false);
    setDeleteDialogOpen(false);
    setEditDialogOpen(false);
  };

  const onRowAction = row => {
    setId(row[4]);
    setRowData(row);
  };

  const updateSnackbar = (success, message) => {
    setSnackbarSuccess(success);
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };

  return (
    <React.Fragment>
      <Snackbar
        snackbarMessage={snackbarMessage}
        snackbarSuccess={snackbarSuccess}
        snackbarOpen={snackbarOpen}
        setSnackbarOpen={setSnackbarOpen}
      />

      <Button
        className={classes.categoryButton}
        onClick={handleCreateClick}
        variant="contained"
        color={"primary"}
        startIcon={<AddIcon>className={classes.extendedIcon}</AddIcon>}
        align={"right"}
      >
        Add row
      </Button>
      <ApiProvider
        initialSortField="id"
        initialEndpoint="kpi"
        lastUpdated={lastUpdated}
      >
        <ContextDataTable columns={columns} />
      </ApiProvider>

      <CreateDialog
        open={createDialogOpen}
        endpoint={"kpi"}
        handleClose={handleClose}
        form={columns}
        onSuccess={handleDataChange}
        updateSnackbar={updateSnackbar}
      />

      <DeleteDialog
        open={deleteDialogOpen}
        endpoint={"kpi"}
        handleClose={handleClose}
        onSuccess={handleDataChange}
        updateSnackbar={updateSnackbar}
        id={id}
      />

      <EditDialog
        open={editDialogOpen}
        endpoint={"kpi"}
        handleClose={handleClose}
        onSuccess={handleDataChange}
        updateSnackbar={updateSnackbar}
        id={id}
        form={columns}
        formData={rowData}
      />
    </React.Fragment>
  );
}

export default Kpi;
