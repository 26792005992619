import { call, put, takeLatest } from "redux-saga/effects";
import { fetchChargeInfo as fetchChargeInfoApi } from "../api/index";
import * as actions from "../actions";

function* fetchChargeInfo() {
  try {
    const data = yield call(fetchChargeInfoApi);
    const type = Object.keys(data._embedded)[0];
    let chargeInfo = data._embedded[type];
    chargeInfo.forEach(obj => delete obj["_links"]);
    yield put(actions.fetchChargeInfoSuccess(chargeInfo));
  } catch (e) {
    yield put({ type: actions.CHARGE_INFO_ERROR });
  }
}

export default [takeLatest(actions.CHARGE_INFO_REQUESTED, fetchChargeInfo)];
