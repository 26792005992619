import React from "react";
import PropTypes from "prop-types";
import { format as formatDate, parse } from "date-fns";

import Box from "@material-ui/core/Box";
import InfoCard from "./InfoCard";
import { formatDuration } from "../../../shared/helpers/duration";

function Dashboard({ status, runningTime, averageTime, steps, reconDate }) {
  return (
    <React.Fragment>
      <Box
        pb={4}
        display="flex"
        justifyContent="space-between"
        color="info.main"
      >
        <Box fontSize="3rem">
          {`Reconciliation ${formatDate(
            parse(reconDate, "yyyy-MM", new Date()),
            "MMM y"
          ).toUpperCase()}`}
        </Box>
      </Box>
      <Box display="flex" justifyContent="space-between">
        <InfoCard value={status} label="Status" />
        <InfoCard
          value={`${formatDuration(runningTime)} / ${formatDuration(
            averageTime
          )}`}
          label="Runtime / Average time"
        />
        <InfoCard value={`${steps.length} / 14`} label="Progress" />
      </Box>
    </React.Fragment>
  );
}

Dashboard.propTypes = {
  status: PropTypes.string.isRequired,
  runningTime: PropTypes.object.isRequired,
  averageTime: PropTypes.object.isRequired,
  steps: PropTypes.array.isRequired,
  reconDate: PropTypes.string.isRequired
};

export default Dashboard;
