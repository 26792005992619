import Alert from "../../../shared/components/Alert";
import React from "react";
import { Snackbar } from "@material-ui/core";

function SnackbarCustom({
  snackbarSuccess,
  snackbarMessage,
  snackbarOpen,
  setSnackbarOpen
}) {
  return (
    <Snackbar
      open={snackbarOpen}
      autoHideDuration={5000}
      onClose={() => setSnackbarOpen(false)}
    >
      {snackbarSuccess === true ? (
        <Alert severity="success" elevation={6}>
          {snackbarMessage}
        </Alert>
      ) : (
        <Alert severity="error" elevation={6}>
          {snackbarMessage}
        </Alert>
      )}
    </Snackbar>
  );
}

export default SnackbarCustom;
