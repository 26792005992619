import React from "react";
import Box from "@material-ui/core/Box";
import List from "@material-ui/core/List";

import NavItem from "../nav/NavItem";
import NavLink from "../nav/NavLink";
import { ListItem, makeStyles } from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";

const useStyles = makeStyles(theme => ({
  lastElement: {
    position: "fixed",
    bottom: 0,
    paddingBottom: 10
  }
}));

function SideBarSettings() {
  const classes = useStyles();

  return (
    <Box>
      <Box fontSize={18} p={2}>
        Settings
      </Box>
      <Box fontSize={14} p={2}>
        Table editor
      </Box>
      <List component="nav" disablePadding>
        <NavItem>
          <NavLink to="/manual-entity-allocation">
            Manual entity allocation
          </NavLink>
        </NavItem>

        <NavItem>
          <NavLink to="/kpi">KPI</NavLink>
        </NavItem>

        <NavItem>
          <NavLink to="/brand-to-entity">Brand to entity</NavLink>
        </NavItem>

        <ListItem className={classes.lastElement}>
          <Box>
            <NavLink to="/dashboard">
              <MenuIcon />
            </NavLink>
          </Box>
        </ListItem>
      </List>
    </Box>
  );
}

export default SideBarSettings;
