import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
  TextField
} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
import Box from "@material-ui/core/Box";
import { createEntity } from "../api";

const useStyles = makeStyles(theme => ({
  submitButton: {
    color: theme.palette.success.contrastText,
    backgroundColor: theme.palette.success.main,
    "&:hover": {
      backgroundColor: theme.palette.success.dark
    }
  }
}));

function CreateDialog({
  open,
  handleClose,
  endpoint,
  onSuccess,
  form,
  updateSnackbar,
  bodyMapper
}) {
  const classes = useStyles();

  const [formValues, setFormValues] = useState(() => initForm());
  const [fieldStatuses, setFiledStatuses] = useState(() => initFieldStatues());

  function initForm() {
    let initFormValues = {};

    form.forEach(inputField => {
      const { formOptions } = inputField;

      if (formOptions) {
        if (formOptions.hidden) {
          initFormValues[inputField.name] = formOptions.defaultValue;
        } else {
          initFormValues[inputField.name] = "";
        }
      }
    });

    return initFormValues;
  }

  function initFieldStatues() {
    let initFieldStatues = {};

    form.forEach(inputField => {
      const { formOptions } = inputField;

      if (formOptions) {
        if (!formOptions.hidden) {
          initFieldStatues[inputField.name] = {
            error: false,
            errorHelperText: ""
          };
        }
      }
    });

    return initFieldStatues;
  }

  const handleCreate = async () => {
    let response = await createEntity(endpoint, mapBody(formValues));

    if (response.success) {
      updateSnackbar(true, response.message);
      onSuccess();
      onDialogClose();
    } else if (response.errors.length > 0) {
      updateFormErrors(response.errors);
    } else {
      updateSnackbar(false, response.message);
      onDialogClose();
    }
  };

  const clearForm = () => {
    setFormValues(initForm());
    setFiledStatuses(initFieldStatues());
  };

  const onDialogClose = () => {
    handleClose();
    clearForm();
  };

  const updateFormErrors = errors => {
    let updatedFieldStatuses = initFieldStatues();

    errors.forEach(error => {
      let fieldName = error.property ? error.property : error.field;
      let message = error.message ? error.message : error.defaultMessage;

      updatedFieldStatuses[fieldName] = {
        error: true,
        errorHelperText: message
      };
    });

    setFiledStatuses(updatedFieldStatuses);
  };

  function handleChange(event) {
    let updatedForm = { ...formValues };
    updatedForm[this] = event.target.value;
    setFormValues(updatedForm);
  }

  const mapBody = body => {
    if (bodyMapper) {
      return bodyMapper(body);
    }

    return body;
  };

  const generateForm = () => {
    return Object.keys(formValues)
      .filter((key, i) => !form[i].formOptions.hidden)
      .map((key, i) => {
        return (
          <Grid item xs={12} key={`grid-${key}`}>
            <FormControl
              key={`from-control-${key}`}
              fullWidth
              label={form[i].label}
              variant="outlined"
            >
              <TextField
                key={`text-field-${key}`}
                label={form[i].label}
                name={key}
                onChange={handleChange.bind(key)}
                value={formValues[key]}
                error={fieldStatuses[key].error}
                helperText={fieldStatuses[key].errorHelperText}
                required={form[i].formOptions.required}
              />
            </FormControl>
          </Grid>
        );
      });
  };

  return (
    <Dialog open={open}>
      <DialogTitle>Create row</DialogTitle>
      <DialogContent>
        <Box pt={1}>
          <Grid
            container
            spacing={2}
            classes={{ container: classes.gridContainer }}
          >
            {generateForm()}
          </Grid>
        </Box>
      </DialogContent>

      <DialogActions>
        <Button
          type={"submit"}
          onClick={handleCreate}
          size="large"
          variant="contained"
          className={classes.submitButton}
        >
          Yes
        </Button>

        <Button onClick={onDialogClose} size="large" variant="contained">
          No
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default CreateDialog;
