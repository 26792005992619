import { call, put, takeLatest } from "redux-saga/effects";
import { fetchSupplierFiles as fetchSupplierFilesApi } from "../api/index";
import * as actions from "../actions";

function* fetchSupplierFiles({ date }) {
  try {
    const data = yield call(fetchSupplierFilesApi, date);
    yield put(actions.fetchSupplierFilesSuccess(data));
  } catch (e) {
    yield put({ type: actions.SUPPLIER_FILES_ERROR });
  }
}

export default [
  takeLatest(actions.SUPPLIER_FILES_REQUESTED, fetchSupplierFiles)
];
