import React from "react";

import ErrorIcon from "@material-ui/icons/Error";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { Tooltip, withStyles } from "@material-ui/core";
import SupplierCheckPopup from "./SupplierCheckPopup";

const TransparentTooltip = withStyles({
  tooltip: {
    backgroundColor: "transparent",
    minWidth: 400
  }
})(Tooltip);

function CheckIcon({ supplierCheck }) {
  function isDataCorrect(supplierCheck) {
    return (
      isMiDataCorrect(supplierCheck) &&
      isSupplierImportDataCorrect(supplierCheck)
    );
  }

  function isBeforeReconciliation(supplierCheck) {
    return supplierCheck.miDataCount === null;
  }

  function isMiDataCorrect(supplierCheck) {
    return (
      supplierCheck.miDataCount !== null &&
      supplierCheck.miDataCount ===
        supplierCheck.supplierSummaryCount - supplierCheck.zeroRatedCount &&
      supplierCheck.supplierSummaryTotal === supplierCheck.miDataTotal
    );
  }

  function isSupplierImportDataCorrect(supplierCheck) {
    return (
      supplierCheck.supplierDataCount !== null &&
      supplierCheck.supplierDataCount === supplierCheck.supplierSummaryCount &&
      supplierCheck.supplierSummaryTotal === supplierCheck.supplierDataTotal
    );
  }

  function getIcon(supplierCheck) {
    if (isDataCorrect(supplierCheck)) {
      return <CheckCircleIcon style={{ color: "green" }} />;
    } else if (
      isBeforeReconciliation(supplierCheck) &&
      isSupplierImportDataCorrect(supplierCheck)
    ) {
      return <CheckCircleIcon style={{ color: "orange" }} />;
    } else {
      return <ErrorIcon color="error" />;
    }
  }

  return supplierCheck ? (
    <TransparentTooltip
      style={{ backgroundColor: "transparent" }}
      title={
        <SupplierCheckPopup
          supplierCheck={supplierCheck}
          isImportIncorrect={!isSupplierImportDataCorrect(supplierCheck)}
          isMiDataIncorrect={!isMiDataCorrect(supplierCheck)}
          isBeforeReconciliation={isBeforeReconciliation(supplierCheck)}
        />
      }
      placement="bottom-end"
    >
      {getIcon(supplierCheck)}
    </TransparentTooltip>
  ) : (
    <CheckCircleIcon style={{ color: "grey" }} />
  );
}

export default CheckIcon;
