import React from "react";
import PropTypes from "prop-types";
import { format, isEqual } from "date-fns";

import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";

import { Link } from "react-router-dom";

function MonthSelector({ months, splitPoint = 10, selectedDate }) {
  const formatDate = date => format(date, "MMM y");

  const buttonDates = months.slice(0, splitPoint);
  const menuDates = months.slice(splitPoint);

  const createSearchString = date =>
    `?year=${format(date, "yyyy")}&month=${format(date, "MM")}`;

  return (
    <Box display="flex" justifyContent="space-between">
      <div>
        <ButtonGroup aria-label="select date recent" size="small">
          {buttonDates.map((date, i) => (
            <Button
              component={Link}
              to={{
                search: createSearchString(date)
              }}
              key={i}
              color={isEqual(selectedDate, date) && "primary"}
              variant={isEqual(selectedDate, date) ? "contained" : "outlined"}
            >
              {formatDate(date)}
            </Button>
          ))}
        </ButtonGroup>
      </div>
      <div>
        <FormControl size="small" variant="outlined" style={{ width: "185px" }}>
          <Select
            value={
              menuDates.find(date => isEqual(selectedDate, date)) ||
              "placeholder"
            }
            //onChange={e => handleSelect(e.target.value)}
            aria-label="select date older"
          >
            <MenuItem disabled value="placeholder">
              Select date
            </MenuItem>
            {menuDates.map((date, i) => (
              <MenuItem
                key={i}
                value={date}
                component={Link}
                to={{
                  search: createSearchString(date)
                }}
              >
                {formatDate(date)}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
    </Box>
  );
}

MonthSelector.propTypes = {
  months: PropTypes.arrayOf(PropTypes.instanceOf(Date).isRequired).isRequired,
  splitPoint: PropTypes.number,
  selectedDate: PropTypes.instanceOf(Date)
};

export default MonthSelector;
