import React, { useState } from "react";
import { ApiProvider } from "../../japi";
import { ContextDataTable } from "../../shared/components/ContextDataTable";
import { generateRunYearsArray } from "../../shared/helpers/getRunYears";
import { generateRunMonthsArray } from "../../shared/helpers/getRunMonths";
import { DslFilterSwitch } from "../../shared/components/DslFilterSwitch";
import Button from "@material-ui/core/Button";
import UpdateIcon from "@material-ui/icons/Update";
import UpdateChargeReasonDialog from "./components/UpdateChargeReasonDialog";
import { makeStyles } from "@material-ui/core";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "../../shared/components/Alert";

const useStyles = makeStyles(theme => ({
  extendedIcon: {
    marginRight: theme.spacing(0.5)
  },
  categoryButton: {
    marginBottom: "0.5rem"
  }
}));

function SupplierData() {
  const classes = useStyles();
  const columns = [
    {
      name: "id",
      label: "id",
      options: {
        filter: false,
        sort: true,
        display: false
      }
    },
    {
      name: "supplier",
      label: "Supplier",
      options: {
        filter: true,
        filterType: "multiselect",
        filterOptions: {
          names: ["BT-BROADBAND", "TALKTALK_LLU", "TALKTALK_NGA"]
        },
        sort: false
      }
    },
    {
      name: "ssid",
      label: "SSID",
      options: {
        filter: false,
        sort: false
      }
    },
    {
      name: "cli",
      label: "CLI",
      options: {
        filter: false,
        sort: false
      }
    },
    {
      name: "price",
      label: "Price",
      options: {
        filter: true,
        filterType: "textField",
        filterMapper: price => parseFloat(price * 100).toFixed(0),
        sort: false,
        customBodyRender: value => {
          if (value !== undefined) {
            return formatter.format(value);
          }
        }
      }
    },
    {
      name: "chargeReason",
      label: "Charge Type",
      options: {
        filter: true,
        filterType: "multiselect",
        filterOptions: {
          names: [
            "Rental",
            "Account Level Charge",
            "One Off",
            "Account Level",
            "UnCategorised",
            "Zero Rated"
          ]
        },
        sort: false
      }
    },
    {
      name: "chargeDetail",
      label: "Charge Category",
      options: {
        filter: true,
        filterType: "multiselect",
        filterOptions: {
          names: [
            "Account Level Charge",
            "Cease",
            "SLG Rebate",
            "Termination",
            "Connection",
            "Auto Comp",
            "Bar Rental",
            "Broadband Rental",
            "Early Termination",
            "UnCategorised",
            "Zero Rated",
            "LLU Line Rental",
            "SFI",
            "SFI-Focused",
            "Select Services Rental",
            "RTB Rental",
            "Regrade/Modify",
            "AL Rental",
            "Migration",
            "Care Rental",
            "Rental",
            "AL Bandwidth",
            "Elevated Rental",
            "AL Market B Rebate",
            "Fast Track",
            "More Focused Appmt",
            "Abort Order"
          ]
        },
        sort: false
      }
    },
    {
      name: "productName",
      label: "Charge Description",
      options: {
        filter: true,
        filterType: "textField",
        sort: false
      }
    },
    {
      name: "chargeDescription",
      label: "Charge Detail",
      options: {
        filter: true,
        filterType: "textField",
        sort: false,
        display: false
      }
    },
    {
      name: "orderReference",
      label: "Order Reference",
      options: {
        filter: true,
        filterType: "textField",
        sort: false,
        display: false
      }
    },
    {
      name: "chargeFrom",
      label: "Charge From",
      options: {
        filter: true,
        filterType: "textField",
        sort: false
      }
    },
    {
      name: "chargeTo",
      label: "Charge To",
      options: {
        filter: true,
        filterType: "textField",
        sort: false
      }
    },
    {
      name: "year",
      label: "Year",
      options: {
        filter: true,
        filterType: "multiselect",
        filterOptions: {
          names: generateRunYearsArray()
        },
        sort: false
      }
    },
    {
      name: "month",
      label: "Month",
      options: {
        filter: true,
        filterType: "multiselect",
        filterOptions: {
          names: generateRunMonthsArray()
        },
        sort: false
      }
    },
    {
      name: "downstream",
      label: "Downstream",
      options: {
        filter: false,
        sort: false
      }
    },
    {
      name: "upstream",
      label: "Upstream",
      options: {
        filter: false,
        sort: false
      }
    },
    {
      name: "market",
      label: "Market",
      options: {
        filter: true,
        filterType: "multiselect",
        filterOptions: {
          names: ["A", "B"]
        },
        sort: false
      }
    },
    {
      name: "accessTechnology",
      label: "Access Technology",
      options: {
        filter: true,
        filterType: "multiselect",
        filterOptions: {
          names: ["ADSL2plus", "FTTC", "FTTP", "IPStream", "SOGEA", "ADSL"]
        },
        sort: false
      }
    }
  ];

  //Dialog props, allow multi-select & edit
  const props = {
    selectableRows: "multiple",
    selectableRowsOnClick: true
  };

  function handleClickOpen() {
    setOpen(true);
  }

  function handleClose() {
    setOpen(false);
  }

  //Callback function to update state of selected rows returned from sibling component table
  const handleRowsSelected = value => {
    setSelectedRows(value);
  };

  function handleExited() {
    setOpen(false);
  }

  const formatter = new Intl.NumberFormat("en-GB", {
    style: "currency",
    currency: "GBP"
  });

  //Modal related state
  const [open, setOpen] = useState(false);
  const cdt = new Date();
  cdt.setMonth(cdt.getMonth()); //mth index starts at 0, thus -1 month behind by default

  //Table Filter state
  const [initFilter] = useState(new Map([["chargeDetail", "ne(Zero Rated)"]]));
  const [switchFilter] = useState("ne(Zero Rated)");
  const [initialSort] = useState("chargeFrom");
  const [initialEndpoint] = useState("supplier-row-data");
  const [searchFields] = useState(["cli", "identifier"]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [lastUpdated, setLastUpdated] = useState();

  // snackbar state
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarSuccess, setSnackbarSuccess] = useState(false);
  const [snackbarMessages, setSnackbarMessages] = useState([]);

  return (
    <React.Fragment>
      <Button
        className={classes.categoryButton}
        onClick={handleClickOpen}
        variant="contained"
        color={"primary"}
        startIcon={<UpdateIcon>className={classes.extendedIcon}</UpdateIcon>}
        align={"right"}
      >
        Update Category
      </Button>
      <UpdateChargeReasonDialog
        open={open}
        onExited={handleExited}
        onClose={handleClose}
        selectedRows={selectedRows}
        onUpdateFailure={errors => {
          setSnackbarOpen(true);
          setSnackbarSuccess(false);
          setSnackbarMessages(errors !== undefined ? errors : []);
        }}
        onUpdateSuccess={() => {
          setSnackbarOpen(true);
          setSnackbarSuccess(true);
          setSnackbarMessages([
            "The charge categorisation(s) have successfully been updated."
          ]);
          //refresh table
          setLastUpdated(Date.now());
        }}
      />
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
      >
        {snackbarSuccess === true ? (
          <Alert severity="success" elevation={6}>
            {snackbarMessages.map((message, i) => (
              <div key={i} id={i}>
                {message}
              </div>
            ))}
          </Alert>
        ) : (
          <Alert severity="error" elevation={6}>
            {snackbarMessages.length > 0 ? (
              snackbarMessages.map((error, i) => (
                <div key={i} id={i}>
                  {error}
                </div>
              ))
            ) : (
              <div>
                There was a problem trying to update the charge categorisations,
                please review your selection and try again.
              </div>
            )}
          </Alert>
        )}
      </Snackbar>
      <ApiProvider
        initialEndpoint={initialEndpoint}
        projectionName={"supplierRowDataProjection"}
        initialSortField={initialSort}
        initialFilter={initFilter}
        searchFields={searchFields}
        lastUpdated={lastUpdated}
        waitForFilters={true}
      >
        <DslFilterSwitch
          field={"chargeDetail"}
          switchFilter={switchFilter}
          initialState={false}
          switchLabel={"Show Zero Rated"}
        />
        <ContextDataTable
          columns={columns}
          allowSearch
          props={props}
          handleRowsSelected={handleRowsSelected}
        />
      </ApiProvider>
    </React.Fragment>
  );
}

export default SupplierData;
