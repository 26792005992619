import React, { useContext, useEffect, useState } from "react";
import { API_PLATFORM_BASE_URL } from "../config";
import { getTokenSilently } from "../react-auth0-spa";

export const ApiContext = React.createContext();
export const useApi = () => useContext(ApiContext);
export const ApiProvider = ({
  children,
  searchFields,
  initialEndpoint,
  initialSize,
  initialSortField,
  initialFilter,
  lastUpdated,
  waitForFilters,
  projectionName
}) => {
  const [endpoint] = useState(initialEndpoint);
  const [term, setTerm] = useState("");
  const [customFilter, setCustomFilter] = useState(
    initialFilter != null ? initialFilter : new Map()
  );
  const [filter, setFilter] = useState(new Map());
  const [page, setPage] = useState(0);
  const [size, setSize] = useState(initialSize ? initialSize : 20);
  const [loading, setLoading] = useState(true);
  const [headers, setHeaders] = useState([]);
  const [data, setData] = useState([]);
  const [paging, setPaging] = useState({ size: size });
  const [error, setErrors] = useState(false);
  const [sortField, setSortField] = useState(initialSortField);
  const [exportUrl, setExportUrl] = useState();
  const [emptyBodyMessage, setEmptyBodyMessage] = useState(
    "Sorry, no matching records found"
  );

  const updatePage = async page => {
    setLoading(true);
    setPage(page);
  };

  const updateSize = async size => {
    setLoading(true);
    setData([]);
    setSize(size);
  };

  const getCustomFilter = () => {
    return customFilter;
  };

  const updateCustomFilter = async customFilter => {
    setLoading(true);
    setData([]);
    setCustomFilter(customFilter);
  };

  const updateFilter = async filter => {
    setLoading(true);
    setData([]);
    setFilter(filter);
  };

  const search = async term => {
    setTerm(term);
  };

  const updateSort = async sort => {
    setLoading(true);
    setData([]);
    setSortField(sort);
  };

  const getFullDataExport = async () => {
    const token = await getTokenSilently();
    return await fetch(exportUrl, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
  };

  const skipInit = () => {
    setEmptyBodyMessage("Please specify filter or use search first.");
    setData([]);
    setLoading(false);
  };

  const getErrorMessage = statusCode => {
    if (statusCode === 504) {
      return "Query timed out.";
    } else if (statusCode >= 500) {
      return "A server error occurred.";
    } else if (statusCode >= 400) {
      return "No records found due to wrong query.";
    } else {
      return "Sorry, no matching records found.";
    }
  };

  useEffect(() => {
    if (waitForFilters && !((term !== "" && term != null) || filter.size > 0)) {
      skipInit();
    } else {
      setLoading(true);
      setEmptyBodyMessage("Sorry, no matching records found.");
      // Standard page load
      let searchParams = {
        page: page,
        size: size
      };

      let queryString = new URLSearchParams(searchParams);

      if (sortField) {
        if (Array.isArray(sortField)) {
          sortField.forEach(it => queryString.append("sort", `${it},desc`));
        } else {
          queryString.append("sort", `${sortField},desc`);
        }
      }

      // Apply search fields
      if (term && searchFields && searchFields.length > 0) {
        searchFields.forEach(field => {
          queryString.append(field, term);
        });
      }

      //Apply Initial custom filters
      if (customFilter.size > 0) {
        customFilter.forEach((value, field) => {
          if (filter.has(field)) {
            customFilter.delete(field);
          } else {
            queryString.append(field, value);
          }
        });
      }

      if (filter.size > 0) {
        let val = "";
        filter.forEach((values, field) => {
          for (let i = 0; i < values.length; i++) {
            val = values[i];
            queryString.append(field, val);
          }
        });
      }

      if (projectionName) {
        queryString.append("projection", projectionName);
      }
      // Build Base & Set Export URL
      const url = `${API_PLATFORM_BASE_URL}/${endpoint}?${queryString}`;
      setExportUrl(
        url
          .replace(new RegExp(/(size=)\d+/), "size=100000")
          .replace(new RegExp(/(page=)\d+/), "page=0")
      );

      //Request Data
      const get = async url => {
        const token = await getTokenSilently();
        const response = await fetch(url, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }).catch(err => {
          setErrors(err);
          setLoading(false);
        });

        if (response !== undefined) {
          if (response.status >= 200 && response.status < 300) {
            response
              .json()
              .then(res => {
                const type = Object.keys(res._embedded)[0];
                let embeddedElement = res._embedded[type];

                // Header
                if (embeddedElement.length > 0) {
                  setHeaders(
                    Object.keys(res._embedded[type][0]).filter(
                      col => col !== "_links"
                    )
                  );
                }

                // Page
                setPaging(res.page);
                // Data
                embeddedElement.forEach(obj => delete obj["_links"]);
                setData(embeddedElement);
              })
              .catch(err => {
                setErrors(err);
              })
              .finally(() => setLoading(false));
          } else {
            setEmptyBodyMessage(getErrorMessage(response.status));
            setLoading(false);
          }
        }
      };

      get(url);
    }
  }, [
    customFilter,
    filter,
    term,
    endpoint,
    page,
    size,
    sortField,
    searchFields,
    lastUpdated,
    waitForFilters,
    projectionName
  ]);

  return (
    <ApiContext.Provider
      value={{
        error,
        data,
        headers,
        paging,
        loading,
        page,
        emptyBodyMessage,
        getCustomFilter,
        updateCustomFilter,
        updatePage,
        updateSize,
        getFullDataExport,
        updateFilter,
        updateSort,
        search
      }}
    >
      {children}
    </ApiContext.Provider>
  );
};
