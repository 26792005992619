import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core";

import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
import MenuItem from "@material-ui/core/MenuItem";

import { updateChargeInfo } from "../api";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import DialogContentText from "@material-ui/core/DialogContentText";
import TextField from "@material-ui/core/TextField";
import { CHARGE_INFO_REQUESTED } from "../actions";

const useStyles = makeStyles(theme => ({
  gridContainer: {
    paddingBottom: theme.spacing(1)
  },
  submitButton: {
    color: theme.palette.success.contrastText,
    backgroundColor: theme.palette.success.main,
    "&:hover": {
      backgroundColor: theme.palette.success.dark
    }
  }
}));

function UpdateChargeReasonDialog({
  open,
  onClose,
  selectedRows,
  onUpdateSuccess,
  onUpdateFailure
}) {
  const dispatch = useDispatch();
  const { data, error } = useSelector(state => state.chargeInfo);
  const [selectedChargeDetail, setSelectedChargeDetail] = useState("none");
  const [selectedChargeDetailIndex, setSelectedChargeDetailIndex] = useState(
    "none"
  );
  const [selectedChargeReason, setSelectedChargeReason] = useState("none");

  //Validate form input
  function isValid() {
    return (
      selectedRows.length > 0 &&
      selectedChargeDetail !== "none" &&
      selectedChargeReason !== "none"
    );
  }

  // fetch a list of charge info from the API each time that the dialog opens
  useEffect(() => {
    open && dispatch({ type: CHARGE_INFO_REQUESTED });
  }, [dispatch, open]);

  function handleClose() {
    onClose();
  }

  //Set state variables with charge related information
  function handleChargeInfo(e) {
    const index = e.target.value;
    setSelectedChargeDetailIndex(index);
    setSelectedChargeDetail(data[index].chargeDetail);
    setSelectedChargeReason(data[index].chargeReason);
  }

  async function handleSubmit() {
    let chargeInfoList = [];

    //Build request list
    for (let i = 0; i < selectedRows.length; i++) {
      let chargeInfo = {
        id: selectedRows[i].id,
        chargeReason: selectedChargeReason,
        chargeDetail: selectedChargeDetail
      };
      chargeInfoList.push(chargeInfo);
    }

    //Invoke requests and check for any failures, and relay to UI
    let response = await updateChargeInfo(chargeInfoList);

    const failureList = response
      .map((res, i) => (res[1] === "false" ? i : -1))
      .filter(index => index !== -1);

    if (failureList.length === 0) {
      onUpdateSuccess();
    } else {
      onUpdateFailure([
        "There has been an error updating your record(s), please review and retry later."
      ]);
    }
    handleClose();
  }

  const classes = useStyles();

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="create-schedule-form"
    >
      <DialogTitle id="charge-info-update-form" disableTypography>
        <Box component="h2" fontSize="1.5rem" fontWeight="400" m={0} pb={5}>
          Update Charge Categorisation
        </Box>
        <Box component="h3" fontSize="1rem" fontWeight="500" m={0}>
          Selected IDs: {selectedRows.length}
        </Box>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          Please provide the charge detail, review your selection and click
          submit to update the charge detail and reason for the selected
          records.
        </DialogContentText>
      </DialogContent>
      <form>
        <DialogContent>
          <Box pt={1}>
            <Grid
              container
              spacing={2}
              classes={{ container: classes.gridContainer }}
            >
              <Grid item xs={12}>
                <FormControl fullWidth size="small" variant="outlined">
                  <Select
                    value={selectedChargeDetailIndex}
                    onChange={handleChargeInfo}
                  >
                    {selectedChargeDetailIndex === "none" && (
                      <MenuItem key={"none"} disabled value="none">
                        {data.length > 0
                          ? "Select charge detail"
                          : !error
                          ? "Fetching charge detail options..."
                          : "Unable to load options. Please try again."}
                      </MenuItem>
                    )}
                    {data.map((row, i) => (
                      <MenuItem key={i} value={i}>
                        {row.chargeDetail}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl
                  fullWidth
                  label={"Charge Reason"}
                  size="small"
                  variant="outlined"
                >
                  <TextField
                    label={"Charge Reason"}
                    name="chargeReason"
                    value={selectedChargeReason}
                    disabled={true}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            className={classes.submitButton}
            size="large"
            variant="contained"
            onClick={handleSubmit}
            disabled={!isValid()}
          >
            Submit
          </Button>
          <Button onClick={handleClose} size="large" variant="text">
            Cancel
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

UpdateChargeReasonDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  selectedRows: PropTypes.array.isRequired,
  onUpdateSuccess: PropTypes.func.isRequired,
  onUpdateFailure: PropTypes.func.isRequired
};

export default UpdateChargeReasonDialog;
