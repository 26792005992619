import React from "react";
import PropTypes from "prop-types";
import { NavLink as ReactRouterNavLink } from "react-router-dom";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  root: {
    "text-decoration": "none",
    color: theme.palette.text.primary,
    "&:hover": {
      color: theme.palette.secondary.main
    }
  },
  active: {
    color: theme.palette.secondary.main
  }
}));

function NavLink({ to, children }) {
  const classes = useStyles();
  return (
    <ReactRouterNavLink
      to={to}
      className={classes.root}
      activeClassName={classes.active}
    >
      {children}
    </ReactRouterNavLink>
  );
}

NavLink.propTypes = {
  to: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired
};

export default NavLink;
