export const SUPPLIER_FILES_REQUESTED = "SUPPLIER_FILES_REQUESTED";
export const SUPPLIER_FILES_SUCCESS = "SUPPLIER_FILES_SUCCESS";
export const SUPPLIER_FILES_ERROR = "SUPPLIER_FILES_ERROR";

export function fetchSupplierFilesSuccess(payload) {
  return {
    type: SUPPLIER_FILES_SUCCESS,
    payload
  };
}
