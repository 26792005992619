import React from "react";
import PropTypes from "prop-types";
import ListItem from "@material-ui/core/ListItem";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  root: {
    height: "40px"
  }
}));

function NavItem({ children }) {
  const classes = useStyles();
  return (
    <ListItem divider className={classes.root}>
      {children}
    </ListItem>
  );
}

NavItem.propTypes = {
  children: PropTypes.node.isRequired
};

export default NavItem;
