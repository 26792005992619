import React, { useState } from "react";
import { ApiProvider } from "../../japi";
import { ContextDataTable } from "../../shared/components/ContextDataTable";
import { generateRunYearsArray } from "../../shared/helpers/getRunYears";
import { generateRunMonthsArray } from "../../shared/helpers/getRunMonths";

function MainSummary() {
  const columns = [
    {
      name: "selectedInvoiceYear",
      label: "Year",
      options: {
        filter: true,
        filterType: "multiselect",
        filterOptions: {
          names: generateRunYearsArray()
        },
        sort: false
      }
    },
    {
      name: "selectedInvoiceMonth",
      label: "Month",
      options: {
        filter: true,
        filterType: "multiselect",
        filterOptions: {
          names: generateRunMonthsArray()
        },
        sort: false
      }
    },
    {
      name: "whereFound",
      label: "Found",
      options: {
        filter: true,
        filterType: "textField",
        sort: false,
        display: true
      }
    },
    {
      name: "baseReportServiceId",
      label: "Base Service ID",
      options: {
        filter: true,
        filterType: "textField",
        sort: false,
        display: false
      }
    },
    {
      name: "supplierNameFull",
      label: "Supplier Name Full",
      options: {
        filter: true,
        filterType: "textField",
        sort: false,
        display: false
      }
    },
    {
      name: "supplierName",
      label: "Supplier Name",
      options: {
        filter: true,
        filterType: "textField",
        sort: false,
        display: false
      }
    },
    {
      name: "supplierInvoiceYear",
      label: "Supplier Inv Year",
      options: {
        filter: true,
        filterType: "textField",
        sort: false,
        display: false
      }
    },
    {
      name: "supplierInvoiceMonth",
      label: "Supplier Inv Month",
      options: {
        filter: true,
        filterType: "textField",
        sort: false,
        display: false
      }
    },
    {
      name: "supplierFileReference",
      label: "Supplier File Ref",
      options: {
        filter: true,
        filterType: "textField",
        sort: false,
        display: false
      }
    },
    {
      name: "supplierNumberOfRecords",
      label: "Supplier Record Count",
      options: {
        filter: true,
        filterType: "textField",
        sort: false,
        display: false
      }
    },
    {
      name: "supplierNumberOfRentalRecords",
      label: "Supplier Rental Record Count",
      options: {
        filter: true,
        filterType: "textField",
        sort: false,
        display: false
      }
    },
    {
      name: "supplierNumberOfOneOffRecords",
      label: "Supplier One Off Record Count",
      options: {
        filter: true,
        filterType: "textField",
        sort: false,
        display: false
      }
    },
    {
      name: "supplierServiceId",
      label: "Supplier Service ID",
      options: {
        filter: true,
        filterType: "textField",
        sort: false,
        display: true
      }
    },
    {
      name: "supplierProductName",
      label: "Supplier Product Name",
      options: {
        filter: true,
        filterType: "textField",
        sort: false,
        display: false
      }
    },
    {
      name: "supplierChargeDescription",
      label: "Supplier Charge Desc",
      options: {
        filter: true,
        filterType: "textField",
        sort: false,
        display: false
      }
    },
    {
      name: "supplierOtherRentalDescription",
      label: "Supplier Other Rental Desc",
      options: {
        filter: true,
        filterType: "textField",
        sort: false,
        display: false
      }
    },
    {
      name: "supplierStartDate",
      label: "Supplier Start Date",
      options: {
        filter: true,
        filterType: "textField",
        sort: false,
        display: false
      }
    },
    {
      name: "supplierEndDate",
      label: "Supplier End Date",
      options: {
        filter: true,
        filterType: "textField",
        sort: false,
        display: false
      }
    },
    {
      name: "supplierOneOffStartDate",
      label: "Supplier One Off Start Date",
      options: {
        filter: true,
        filterType: "textField",
        sort: false,
        display: false
      }
    },
    {
      name: "supplierOneOffEndDate",
      label: "Supplier One Off End Date",
      options: {
        filter: true,
        filterType: "textField",
        sort: false,
        display: false
      }
    },
    {
      name: "supplierBroadbandCost",
      label: "Supplier BB Cost",
      options: {
        filter: true,
        filterType: "textField",
        sort: false,
        display: true,
        customBodyRender: value => {
          if (value !== undefined) {
            return formatter.format(value);
          }
        }
      }
    },
    {
      name: "supplierLluCost",
      label: "Supplier LLU Cost",
      options: {
        filter: true,
        filterType: "textField",
        sort: false,
        display: false,
        customBodyRender: value => {
          if (value !== undefined) {
            return formatter.format(value);
          }
        }
      }
    },
    {
      name: "supplierBarRental",
      label: "Supplier Bar Rental",
      options: {
        filter: true,
        filterType: "textField",
        sort: false,
        display: false,
        customBodyRender: value => {
          if (value !== undefined) {
            return formatter.format(value);
          }
        }
      }
    },
    {
      name: "supplierSelectedServicesRental",
      label: "Supplier Selected Services Rental",
      options: {
        filter: true,
        filterType: "textField",
        sort: false,
        display: false,
        customBodyRender: value => {
          if (value !== undefined) {
            return formatter.format(value);
          }
        }
      }
    },
    {
      name: "supplierCareCost",
      label: "Supplier Care Cost",
      options: {
        filter: true,
        filterType: "textField",
        sort: false,
        display: false,
        customBodyRender: value => {
          if (value !== undefined) {
            return formatter.format(value);
          }
        }
      }
    },
    {
      name: "supplierElevatedCost",
      label: "Supplier Elevated Cost",
      options: {
        filter: true,
        filterType: "textField",
        sort: false,
        display: false,
        customBodyRender: value => {
          if (value !== undefined) {
            return formatter.format(value);
          }
        }
      }
    },
    {
      name: "supplierRealTimeBandwidthCost",
      label: "Supplier RTB Cost",
      options: {
        filter: true,
        filterType: "textField",
        sort: false,
        display: false,
        customBodyRender: value => {
          if (value !== undefined) {
            return formatter.format(value);
          }
        }
      }
    },
    {
      name: "supplierOneOffAbortOrder",
      label: "Supplier One Off Abort Order",
      options: {
        filter: true,
        filterType: "textField",
        sort: false,
        display: false,
        customBodyRender: value => {
          if (value !== undefined) {
            return formatter.format(value);
          }
        }
      }
    },
    {
      name: "supplierOneOffBandwidth",
      label: "Supplier One Off Bandwidth",
      options: {
        filter: true,
        filterType: "textField",
        sort: false,
        display: false,
        customBodyRender: value => {
          if (value !== undefined) {
            return formatter.format(value);
          }
        }
      }
    },
    {
      name: "supplierOneOffCease",
      label: "Supplier One Off Cease",
      options: {
        filter: true,
        filterType: "textField",
        sort: false,
        display: false,
        customBodyRender: value => {
          if (value !== undefined) {
            return formatter.format(value);
          }
        }
      }
    },
    {
      name: "supplierOneOffConnection",
      label: "Supplier One Off Connection",
      options: {
        filter: true,
        filterType: "textField",
        sort: false,
        display: false,
        customBodyRender: value => {
          if (value !== undefined) {
            return formatter.format(value);
          }
        }
      }
    },
    {
      name: "supplierOneOffFastTrack",
      label: "Supplier One Off Fast Track",
      options: {
        filter: true,
        filterType: "textField",
        sort: false,
        display: false,
        customBodyRender: value => {
          if (value !== undefined) {
            return formatter.format(value);
          }
        }
      }
    },
    {
      name: "supplierOneOffMktbRebate",
      label: "Supplier One Off Mktb Rebate",
      options: {
        filter: true,
        filterType: "textField",
        sort: false,
        display: false,
        customBodyRender: value => {
          if (value !== undefined) {
            return formatter.format(value);
          }
        }
      }
    },
    {
      name: "supplierOneOffRebate",
      label: "Supplier One Off Rebate",
      options: {
        filter: true,
        filterType: "textField",
        sort: false,
        display: false,
        customBodyRender: value => {
          if (value !== undefined) {
            return formatter.format(value);
          }
        }
      }
    },
    {
      name: "supplierOneOffSfi",
      label: "Supplier One Off Sfi",
      options: {
        filter: true,
        filterType: "textField",
        sort: false,
        display: false,
        customBodyRender: value => {
          if (value !== undefined) {
            return formatter.format(value);
          }
        }
      }
    },
    {
      name: "supplierOneOffTermination",
      label: "Supplier One Off Termination",
      options: {
        filter: true,
        filterType: "textField",
        sort: false,
        display: false,
        customBodyRender: value => {
          if (value !== undefined) {
            return formatter.format(value);
          }
        }
      }
    },
    {
      name: "supplierOneOffTimeRelatedCharge",
      label: "Supplier One Off Time Related Charge",
      options: {
        filter: true,
        filterType: "textField",
        sort: false,
        display: false,
        customBodyRender: value => {
          if (value !== undefined) {
            return formatter.format(value);
          }
        }
      }
    },
    {
      name: "supplierOneOffRegradeModify",
      label: "Supplier One Off Regrade/Modify",
      options: {
        filter: true,
        filterType: "textField",
        sort: false,
        display: false,
        customBodyRender: value => {
          if (value !== undefined) {
            return formatter.format(value);
          }
        }
      }
    },
    {
      name: "supplierOneOffTotalCost",
      label: "Supplier One Off Total Cost",
      options: {
        filter: true,
        filterType: "textField",
        sort: false,
        display: false,
        customBodyRender: value => {
          if (value !== undefined) {
            return formatter.format(value);
          }
        }
      }
    },
    {
      name: "supplierOtherCost",
      label: "Supplier Other Cost",
      options: {
        filter: true,
        filterType: "textField",
        sort: false,
        display: false,
        customBodyRender: value => {
          if (value !== undefined) {
            return formatter.format(value);
          }
        }
      }
    },
    {
      name: "supplierTotalPrice",
      label: "Supplier Total Price",
      options: {
        filter: true,
        filterType: "textField",
        sort: false,
        display: false,
        customBodyRender: value => {
          if (value !== undefined) {
            return formatter.format(value);
          }
        }
      }
    },
    {
      name: "supplierChargeType",
      label: "Supplier Charge Type",
      options: {
        filter: true,
        filterType: "textField",
        sort: false,
        display: false
      }
    },
    {
      name: "supplierCustomerOrderReference",
      label: "Supplier Customer Order Ref",
      options: {
        filter: true,
        filterType: "textField",
        sort: false,
        display: false
      }
    },
    {
      name: "supplierExchange",
      label: "Supplier Exchange",
      options: {
        filter: true,
        filterType: "textField",
        sort: false,
        display: false
      }
    },
    {
      name: "supplierMarket",
      label: "Supplier Market",
      options: {
        filter: true,
        filterType: "textField",
        sort: false,
        display: false
      }
    },
    {
      name: "supplierAccessTechnology",
      label: "Supplier Access Tech",
      options: {
        filter: true,
        filterType: "textField",
        sort: false,
        display: false
      }
    },
    {
      name: "supplierProductType",
      label: "Supplier Product Type",
      options: {
        filter: true,
        filterType: "textField",
        sort: false,
        display: false
      }
    },
    {
      name: "supplierSpeed",
      label: "Supplier Speed",
      options: {
        filter: true,
        filterType: "textField",
        sort: false,
        display: false
      }
    },
    {
      name: "supplierRealTimeBandwidth",
      label: "Supplier Charge Desc",
      options: {
        filter: true,
        filterType: "textField",
        sort: false,
        display: false
      }
    },
    {
      name: "supplierElevatedTraffic",
      label: "Supplier Elevated Traffic",
      options: {
        filter: true,
        filterType: "textField",
        sort: false,
        display: false
      }
    },
    {
      name: "dcSupplierServiceId",
      label: "DC Supplier Service ID",
      options: {
        filter: true,
        filterType: "textField",
        sort: false,
        display: false
      }
    },
    {
      name: "dcCLi",
      label: "DC CLI",
      options: {
        filter: true,
        filterType: "textField",
        sort: false,
        display: false
      }
    },
    {
      name: "dcProductName",
      label: "DC Product Name",
      options: {
        filter: true,
        filterType: "textField",
        sort: false,
        display: false
      }
    },
    {
      name: "dcDescription",
      label: "DC Desc",
      options: {
        filter: true,
        filterType: "textField",
        sort: false,
        display: true
      }
    },
    {
      name: "dcProductStatus",
      label: "DC Product Status",
      options: {
        filter: true,
        filterType: "textField",
        sort: false,
        display: false
      }
    },
    {
      name: "dcStatus",
      label: "DC Status",
      options: {
        filter: true,
        filterType: "textField",
        sort: false,
        display: true
      }
    },
    {
      name: "dcOrderRefRequestId",
      label: "DC Order Ref Req ID",
      options: {
        filter: true,
        filterType: "textField",
        sort: false,
        display: false
      }
    },
    {
      name: "dcProviderReference",
      label: "DC Provider Ref",
      options: {
        filter: true,
        filterType: "textField",
        sort: false,
        display: false
      }
    },
    {
      name: "dcExchangeID",
      label: "DC Exchange ID",
      options: {
        filter: true,
        filterType: "textField",
        sort: false,
        display: false
      }
    },
    {
      name: "dcMarketExchange",
      label: "DC Mkt Exchange",
      options: {
        filter: true,
        filterType: "textField",
        sort: false,
        display: false
      }
    },
    {
      name: "dcAccessTechnology",
      label: "DC Access Technology",
      options: {
        filter: true,
        filterType: "textField",
        sort: false,
        display: false
      }
    },
    {
      name: "dcDownstreamUpstream",
      label: "DC Down/Upstream",
      options: {
        filter: true,
        filterType: "textField",
        sort: false,
        display: false
      }
    },
    {
      name: "dcRealTimeBandWidth",
      label: "DC Realtime Bandwidth",
      options: {
        filter: true,
        filterType: "textField",
        sort: false,
        display: false
      }
    },
    {
      name: "dcRealmSupplier",
      label: "DC Realm Supplier",
      options: {
        filter: true,
        filterType: "textField",
        sort: false,
        display: false
      }
    },
    {
      name: "dcReseller",
      label: "DC Reseller",
      options: {
        filter: true,
        filterType: "textField",
        sort: false,
        display: false
      }
    },
    {
      name: "dcElevated",
      label: "DC Elevated",
      options: {
        filter: true,
        filterType: "textField",
        sort: false,
        display: false
      }
    },
    {
      name: "dcBrand",
      label: "DC Brand",
      options: {
        filter: true,
        filterType: "textField",
        sort: false,
        display: true
      }
    },
    {
      name: "dcDivision",
      label: "DC Division",
      options: {
        filter: true,
        filterType: "textField",
        sort: false,
        display: false
      }
    },
    {
      name: "dcUsername",
      label: "DC Username",
      options: {
        filter: true,
        filterType: "textField",
        sort: false,
        display: false
      }
    },
    {
      name: "dcCareLevel",
      label: "DC Care level",
      options: {
        filter: true,
        filterType: "textField",
        sort: false,
        display: false
      }
    },
    {
      name: "dcTechnologyType",
      label: "DC Technology Type",
      options: {
        filter: true,
        filterType: "textField",
        sort: false,
        display: false
      }
    },
    {
      name: "dcSimProviderReference",
      label: "DC Sim Provider Ref",
      options: {
        filter: true,
        filterType: "textField",
        sort: false,
        display: false
      }
    },
    {
      name: "dcCustomerPurchaseReference",
      label: "DC Customer Purchase Ref",
      options: {
        filter: true,
        filterType: "textField",
        sort: false,
        display: false
      }
    },
    {
      name: "dcProductInstanceBroadbandId",
      label: "DC Product Instance Broadband Id",
      options: {
        filter: true,
        filterType: "textField",
        sort: false,
        display: false
      }
    },
    {
      name: "evoPin",
      label: "EVO Pin",
      options: {
        filter: true,
        filterType: "textField",
        sort: false,
        display: true
      }
    },
    {
      name: "evoInvoiceYear",
      label: "EVO Inv Year",
      options: {
        filter: true,
        filterType: "textField",
        sort: false,
        display: false
      }
    },
    {
      name: "evoInvoiceMonth",
      label: "EVO Inv Month",
      options: {
        filter: true,
        filterType: "textField",
        sort: false,
        display: false
      }
    },
    {
      name: "evoNumberOfRecords",
      label: "EVO No. Of Records",
      options: {
        filter: true,
        filterType: "textField",
        sort: false,
        display: false
      }
    },
    {
      name: "evoProductDescription",
      label: "EVO Product Desc",
      options: {
        filter: true,
        filterType: "textField",
        sort: false,
        display: false
      }
    },
    {
      name: "evoChargeDescription",
      label: "EVO Charge Desc",
      options: {
        filter: true,
        filterType: "textField",
        sort: false,
        display: false
      }
    },
    {
      name: "evoMinStartDate",
      label: "EVO Min. Start Date",
      options: {
        filter: true,
        filterType: "textField",
        sort: false,
        display: false
      }
    },
    {
      name: "evoMaxEndDate",
      label: "EVO Max. End Date",
      options: {
        filter: true,
        filterType: "textField",
        sort: false,
        display: false
      }
    },
    {
      name: "evoRentalRevenue",
      label: "EVO Rental Revenue",
      options: {
        filter: true,
        filterType: "textField",
        sort: false,
        display: true,
        customBodyRender: value => {
          if (value !== undefined) {
            return formatter.format(value);
          }
        }
      }
    },
    {
      name: "evoConnectionCharge",
      label: "EVO Conn Charge",
      options: {
        filter: true,
        filterType: "textField",
        sort: false,
        display: false,
        customBodyRender: value => {
          if (value !== undefined) {
            return formatter.format(value);
          }
        }
      }
    },
    {
      name: "evoEventCharge",
      label: "EVO Event Charge",
      options: {
        filter: true,
        filterType: "textField",
        sort: false,
        display: false,
        customBodyRender: value => {
          if (value !== undefined) {
            return formatter.format(value);
          }
        }
      }
    },
    {
      name: "evoProvisioningCharge",
      label: "EVO Provisioning Charge",
      options: {
        filter: true,
        filterType: "textField",
        sort: false,
        display: false,
        customBodyRender: value => {
          if (value !== undefined) {
            return formatter.format(value);
          }
        }
      }
    },
    {
      name: "evoSelectServices",
      label: "EVO Select Services",
      options: {
        filter: true,
        filterType: "textField",
        sort: false,
        display: false,
        customBodyRender: value => {
          if (value !== undefined) {
            return formatter.format(value);
          }
        }
      }
    },
    {
      name: "evoOtherRevenue",
      label: "EVO Other Revenue",
      options: {
        filter: true,
        filterType: "textField",
        sort: false,
        display: false,
        customBodyRender: value => {
          if (value !== undefined) {
            return formatter.format(value);
          }
        }
      }
    },
    {
      name: "evoTotalRevenue",
      label: "EVO Total Revenue",
      options: {
        filter: true,
        filterType: "textField",
        sort: false,
        display: false,
        customBodyRender: value => {
          if (value !== undefined) {
            return formatter.format(value);
          }
        }
      }
    },
    {
      name: "evoNominal",
      label: "EVO Nominal",
      options: {
        filter: true,
        filterType: "textField",
        sort: false,
        display: true
      }
    },
    {
      name: "evoBrand",
      label: "EVO Brand",
      options: {
        filter: true,
        filterType: "textField",
        sort: false,
        display: false
      }
    },
    {
      name: "evoDivision",
      label: "EVO Division",
      options: {
        filter: true,
        filterType: "textField",
        sort: false,
        display: false
      }
    },

    {
      name: "evoResellerAccountName",
      label: "EVO Reseller Acc Name",
      options: {
        filter: true,
        filterType: "textField",
        sort: false,
        display: false
      }
    },

    {
      name: "evoResellerName",
      label: "EVO Reseller Name",
      options: {
        filter: true,
        filterType: "textField",
        sort: false,
        display: false
      }
    },

    {
      name: "cli",
      label: "CLI",
      options: {
        filter: true,
        filterType: "textField",
        sort: false,
        display: true
      }
    },
    {
      name: "evoServiceId",
      label: "EVO Service ID",
      options: {
        filter: true,
        filterType: "textField",
        sort: false,
        display: false
      }
    },
    {
      name: "evoServiceCostId",
      label: "EVO Service Cost ID",
      options: {
        filter: true,
        filterType: "textField",
        sort: false,
        display: true
      }
    }
  ];

  const formatter = new Intl.NumberFormat("en-GB", {
    style: "currency",
    currency: "GBP"
  });

  const [initialSort] = useState([
    "selectedInvoiceYear",
    "selectedInvoiceMonth"
  ]);
  const [initialEndpoint] = useState("main-summary");
  const [searchFields] = useState([
    "cli",
    "supplierServiceId",
    "dcSupplierServiceId"
  ]);

  return (
    <React.Fragment>
      <ApiProvider
        initialEndpoint={initialEndpoint}
        initialSortField={initialSort}
        searchFields={searchFields}
      >
        <ContextDataTable columns={columns} allowSearch />
      </ApiProvider>
    </React.Fragment>
  );
}

export default MainSummary;
