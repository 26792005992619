import { createTheme } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";

const defaultTheme = createTheme();

const theme = createTheme({
  palette: {
    primary: {
      main: "#022f6b",
      contrastText: "#fff"
    },
    secondary: {
      main: "#f29400"
    },
    info: {
      main: "#022f6b"
    },
    error: {
      main: "#a30115"
    },
    warning: {
      main: "#f29400"
    },
    success: {
      main: "#50b063",
      contrastText: "#fff"
    },
    background: {
      default: "#f1f1f1"
    }
  },
  shape: {
    borderRadius: 5
  },
  typography: {
    // rem values are relative to browser default of 16px eg. 0.875rem = 14px
    button: {
      fontWeight: 400,
      textTransform: "none"
    },
    h6: {
      fontSize: "1.125rem",
      fontWeight: 400
    },
    body1: {
      fontSize: "0.875rem",
      lineHeight: "normal"
    },
    body2: {
      fontSize: "0.75rem",
      lineHeight: "normal"
    }
  },
  props: {
    // override default component props
    MuiButton: {
      disableElevation: true
    },
    MuiCheckbox: {
      color: "primary"
    },
    MuiCircularProgress: {
      disableShrink: true,
      size: 48,
      thickness: 5
    },
    MuiTableContainer: {
      variant: "outlined",
      component: Paper
    }
  },
  overrides: {
    // css overrides
    MuiButton: {
      outlined: {
        backgroundColor: "white",
        color: defaultTheme.palette.text.secondary
      }
    },
    MuiInputBase: {
      input: {
        fontSize: "0.875rem"
      }
    },
    MuiSelect: {
      outlined: {
        backgroundColor: "white",
        color: defaultTheme.palette.text.secondary
      }
    },
    MuiDialogActions: {
      root: {
        justifyContent: "flex-start",
        paddingTop: defaultTheme.spacing(3),
        paddingRight: defaultTheme.spacing(5),
        paddingBottom: defaultTheme.spacing(5),
        paddingLeft: defaultTheme.spacing(5)
      }
    },
    MuiDialogTitle: {
      root: {
        paddingTop: defaultTheme.spacing(5),
        paddingRight: defaultTheme.spacing(5),
        paddingBottom: defaultTheme.spacing(3),
        paddingLeft: defaultTheme.spacing(5)
      }
    },
    MuiDialogContent: {
      root: {
        paddingTop: defaultTheme.spacing(1),
        paddingRight: defaultTheme.spacing(5),
        paddingBottom: defaultTheme.spacing(1),
        paddingLeft: defaultTheme.spacing(5)
      }
    }
  }
});

window.theme = theme; // expose the current theme to window object for easy access when debugging

export default theme;
