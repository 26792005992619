import { call, put, takeLatest } from "redux-saga/effects";
import { fetchSuppliers as fetchSuppliersApi } from "../api/index";
import * as actions from "../actions";

function* fetchSuppliers() {
  try {
    const data = yield call(fetchSuppliersApi);
    yield put(actions.fetchSuppliersSuccess(data));
  } catch (e) {
    console.error(e);
  }
}

export default [takeLatest(actions.SUPPLIERS_REQUESTED, fetchSuppliers)];
