import React, { useEffect } from "react";
import PropTypes from "prop-types";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";

let rootDocumentTitle = "";

function Main({ title, children }) {
  useEffect(() => {
    // cache the original document title
    if (!rootDocumentTitle) {
      rootDocumentTitle = document.title;
    }

    // append title from props to document title
    document.title = rootDocumentTitle + (title ? ` - ${title}` : "");

    if (rootDocumentTitle) {
      return function() {
        // reset the original document title when this component unmounts
        document.title = rootDocumentTitle;
      };
    }
  });

  return (
    <React.Fragment>
      {title && (
        <Typography variant="h4" component="h1" gutterBottom>
          {title}
        </Typography>
      )}
      <Box my={3}>{children}</Box>
    </React.Fragment>
  );
}

Main.propTypes = {
  title: PropTypes.string
};

export default Main;
