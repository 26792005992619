import { startOfMonth, subMonths } from "date-fns";

function generateMonths(limit = 132) {
  let months = [startOfMonth(new Date())];
  for (let i = 0; i < limit; i++) {
    months.push(subMonths(months[i], 1));
  }

  return months;
}

export default generateMonths;
