import {
  SUPPLIER_FILES_REQUESTED,
  SUPPLIER_FILES_SUCCESS,
  SUPPLIER_FILES_ERROR
} from "../actions";

const initialState = {
  loading: false,
  error: false,
  data: []
};

export default function(state = initialState, action) {
  switch (action.type) {
    case SUPPLIER_FILES_REQUESTED: {
      return { ...state, loading: true, error: false };
    }
    case SUPPLIER_FILES_SUCCESS:
      return { ...state, data: action.payload, loading: false, error: false };
    case SUPPLIER_FILES_ERROR:
      return { ...state, loading: false, error: true };
    default:
      return state;
  }
}
