import React, { useState } from "react";
import { ApiProvider } from "../../japi";
import { ContextDataTable } from "../../shared/components/ContextDataTable";
import { generateRunMonthsArray } from "../../shared/helpers/getRunMonths";
import { generateRunYearsArray } from "../../shared/helpers/getRunYears";
import UpdateIcon from "@material-ui/icons/Update";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core";
import AllocateEntityDialog from "./components/AllocateEntityDialog";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "../../shared/components/Alert";
import AllocateSSIDDialog from "./components/AllocateSSIDDialog";
import Grid from "@material-ui/core/Grid";
import { isEmpty } from "../../util/StringUtils";
import OverrideDialog from "./components/OverrideDialog";

const useStyles = makeStyles(theme => ({
  extendedIcon: {
    marginRight: theme.spacing(0.5)
  },
  categoryButton: {
    marginBottom: "0.5rem"
  }
}));

function MiData() {
  const classes = useStyles();

  const columns = [
    {
      name: "id",
      label: "ID",
      options: {
        filter: false,
        sort: false,
        display: false
      }
    },
    {
      name: "selectedInvoiceYear",
      label: "Year",
      options: {
        filter: true,
        filterType: "multiselect",
        filterOptions: {
          names: generateRunYearsArray()
        },
        sort: false
      }
    },
    {
      name: "selectedInvoiceMonth",
      label: "Month",
      options: {
        filter: true,
        filterType: "multiselect",
        filterOptions: {
          names: generateRunMonthsArray()
        },
        sort: false
      }
    },
    {
      name: "supplier",
      label: "Supplier",
      options: {
        filter: true,
        filterType: "multiselect",
        filterOptions: {
          names: ["BTW", "TTB"]
        },
        sort: false
      }
    },
    {
      name: "supplierAcct",
      label: "Supplier Account",
      options: {
        filter: true,
        filterType: "multiselect",
        filterOptions: {
          names: [
            "0455815481",
            "0455823500",
            "ATL_LLU",
            "ATL_NGA",
            "DC2_LLU",
            "DCB_IPS",
            "DCB_LLU",
            "DCB_NGA",
            "DCC_LLU",
            "FLE_IPS",
            "MPX_LLU",
            "MPX_NGA",
            "NCT_LLU",
            "NT2_LLU",
            "OBW_LLU"
          ]
        },
        sort: false
      }
    },
    {
      name: "ssid",
      label: "SSID",
      options: {
        filter: false,
        sort: false
      }
    },
    {
      name: "cli",
      label: "CLI",
      options: {
        filter: false,
        sort: false
      }
    },
    {
      name: "chargeType",
      label: "Charge Type",
      options: {
        filter: true,
        filterType: "multiselect",
        filterOptions: {
          names: [
            "Rental",
            "Account Level Charge",
            "One Off",
            "Account Level",
            "UnCategorised",
            "Zero Rated"
          ]
        },
        sort: false
      }
    },
    {
      name: "chargeCategory",
      label: "Charge Category",
      options: {
        filter: true,
        filterType: "multiselect",
        filterOptions: {
          names: [
            "Account Level Charge",
            "Cease",
            "SLG Rebate",
            "Termination",
            "Connection",
            "Auto Comp",
            "Bar Rental",
            "Broadband Rental",
            "Early Termination",
            "UnCategorised",
            "Zero Rated",
            "LLU Line Rental",
            "SFI",
            "SFI-Focused",
            "Select Services Rental",
            "RTB Rental",
            "Regrade/Modify",
            "AL Rental",
            "Migration",
            "Care Rental",
            "Rental",
            "AL Bandwidth",
            "Elevated Rental",
            "AL Market B Rebate",
            "Fast Track",
            "More Focused Appmt",
            "Abort Order"
          ]
        },
        sort: false
      }
    },
    {
      name: "chargeReason",
      label: "Charge Reason",
      options: {
        filter: true,
        filterType: "textField",
        sort: false
      }
    },
    {
      name: "chargeDetail",
      label: "Charge Detail",
      options: {
        filter: true,
        filterType: "textField",
        sort: false,
        display: true
      }
    },
    {
      name: "startDate",
      label: "Start Date",
      options: {
        filter: true,
        filterType: "textField",
        sort: false
      }
    },
    {
      name: "endDate",
      label: "End Date",
      options: {
        filter: true,
        filterType: "textField",
        sort: false
      }
    },
    {
      name: "accessTechnology",
      label: "Access Technology",
      options: {
        filter: true,
        filterType: "multiselect",
        filterOptions: {
          names: ["ADSL2plus", "FTTC", "FTTP", "IPStream", "SOGEA", "ADSL"]
        },
        sort: false,
        display: false
      }
    },
    {
      name: "speed",
      label: "Speed",
      options: {
        filter: true,
        filterType: "textField",
        sort: false,
        display: false
      }
    },
    {
      name: "kpi",
      label: "KPI",
      options: {
        filter: true,
        filterType: "multiselect",
        filterOptions: {
          names: [
            "<NULL>",
            "ADSL 20CN",
            "ADSL 21CN",
            "ADSL MPF",
            "ADSL SMPF",
            "MPF VO",
            "FTTC 40/2",
            "FTTC 40/10",
            "FTTC 80/20",
            "GFAST 160/30",
            "GFAST 330/50",
            "FTTP 0.5/0.5",
            "FTTP 40/10",
            "FTTP 80/20",
            "FTTP 115/20",
            "FTTP 160/30",
            "FTTP 220/20",
            "FTTP 220/30",
            "FTTP 330/30",
            "FTTP 330/50",
            "FTTP 500/165",
            "FTTP 550/75",
            "FTTP 1000/115",
            "FTTP 1000/220",
            "SOGEA 0.5/0.5",
            "SOGEA 40/2",
            "SOGEA 40/10",
            "SOGEA 55/10",
            "SOGEA 80/20",
            "FTTC SOGFAST 8/30"
          ]
        },
        sort: false
      }
    },
    {
      name: "price",
      label: "Price",
      options: {
        filter: true,
        filterType: "textField",
        sort: false,
        customBodyRender: value => {
          if (value !== undefined) {
            return formatter.format(value);
          }
        }
      }
    },
    {
      name: "brand",
      label: "Brand",
      options: {
        filter: true,
        filterType: "textField",
        sort: false
      }
    },
    {
      name: "entity",
      label: "Entity",
      options: {
        filter: true,
        filterType: "multiselect",
        filterOptions: {
          names: ["FLEUR", "<NULL>", "WHSL", "SSE", "RETAIL", "DCS", "ALL"]
        },
        sort: false
      }
    },
    {
      name: "resellerAcct",
      label: "Reseller Account",
      options: {
        filter: true,
        filterType: "textField",
        sort: false
      }
    },
    {
      name: "reconStatus",
      label: "Recon Status",
      options: {
        filter: true,
        filterType: "multiselect",
        filterOptions: {
          names: ["<NULL>", "S", "SD", "SDE"]
        },
        sort: false
      }
    },
    {
      name: "brandFound",
      label: "Brand Found",
      options: {
        filter: true,
        filterType: "multiselect",
        filterOptions: {
          names: [
            "DC Brand found via CLI Match",
            "SSID to PIBB Match",
            "Recon Match in SIH",
            "Evo Brand found via CLI Match",
            "Brand found from Billed CLI",
            "No Brand Found",
            "Manual Entity Allocation"
          ]
        },
        sort: false
      }
    },
    {
      name: "supplierMarket",
      label: "Supplier Market",
      options: {
        filter: true,
        filterType: "textField",
        sort: false,
        display: false
      }
    },
    {
      name: "financeNominal",
      label: "Finance Nominal",
      options: {
        filter: true,
        filterType: "textField",
        sort: false,
        display: false
      }
    },
    {
      name: "costCentre",
      label: "Cost Centre",
      options: {
        filter: true,
        filterType: "textField",
        sort: false,
        display: false
      }
    },
    {
      name: "isFullMonthCharge",
      label: "Full Month",
      options: {
        filter: true,
        filterType: "multiselect",
        filterOptions: {
          names: ["true", "false"]
        },
        customBodyRender: value => {
          if (value === undefined) {
            return "";
          } else if (value === null || value === false) {
            return "0";
          } else {
            return "1";
          }
        },
        sort: false
      }
    }
  ];

  //Dialog props, allow multi-select & edit
  const props = {
    selectableRows: "multiple",
    selectableRowsOnClick: true
  };

  const formatter = new Intl.NumberFormat("en-GB", {
    style: "currency",
    currency: "GBP"
  });

  const [initialSort] = useState("id");
  const [initialEndpoint] = useState("mi-data");
  const [searchFields] = useState(["cli", "ssid"]);

  const [entityAllocationDialogOpen, setEntityAllocationDialogOpen] = useState(
    false
  );
  const [ssidAllocationDialogOpen, setSsidAllocationDialogOpen] = useState(
    false
  );
  const [overrideDialogOpen, setOverrideDialogOpen] = useState(false);

  const [openAllocationDialog, setOpenAllocationDialog] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [lastUpdated, setLastUpdated] = useState();

  // snackbar state
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarSuccess, setSnackbarSuccess] = useState(false);
  const [snackbarMessages, setSnackbarMessages] = useState([]);

  function handleClose() {
    setEntityAllocationDialogOpen(false);
    setSsidAllocationDialogOpen(false);
    setOverrideDialogOpen(false);
  }

  const hasIdentifier = row => !isEmpty(row.ssid);

  const isAllocated = row => {
    return (
      !isEmpty(row.brand) || !isEmpty(row.entity) || !isEmpty(row.resellerAcct)
    );
  };

  const handeAllocationDialogOpen = () => {
    if (selectedRows.some(isAllocated)) {
      setOpenAllocationDialog(true);
      setOverrideDialogOpen(true);
    } else {
      setEntityAllocationDialogOpen(true);
    }
  };

  const handeIdentifierDialogOpen = () => {
    if (selectedRows.some(hasIdentifier)) {
      setOpenAllocationDialog(false);
      setOverrideDialogOpen(true);
    } else {
      setSsidAllocationDialogOpen(true);
    }
  };

  //Callback function to update state of selected rows returned from sibling component table
  const handleRowsSelected = value => {
    setSelectedRows(value);
  };

  const onUpdateFailure = errors => {
    setSnackbarOpen(true);
    setSnackbarSuccess(false);
    setSnackbarMessages(errors !== undefined ? errors : []);
  };

  const onUpdateSuccess = message => {
    setSnackbarOpen(true);
    setSnackbarSuccess(true);
    setSnackbarMessages([message]);
    //refresh table
    setLastUpdated(Date.now());
  };

  return (
    <React.Fragment>
      <Grid container spacing={1}>
        <Grid item>
          <Button
            className={classes.categoryButton}
            onClick={handeAllocationDialogOpen}
            variant="contained"
            color={"primary"}
            startIcon={
              <UpdateIcon>className={classes.extendedIcon}</UpdateIcon>
            }
            align={"right"}
          >
            Allocate Entity
          </Button>
        </Grid>
        <Grid item>
          <Button
            className={classes.categoryButton}
            onClick={handeIdentifierDialogOpen}
            variant="contained"
            color={"primary"}
            align={"right"}
          >
            Assign SSID/CLI
          </Button>
        </Grid>
      </Grid>
      <OverrideDialog
        open={overrideDialogOpen}
        onClose={handleClose}
        openAllocationDialog={openAllocationDialog}
        handleOpenAllocationDialog={() => setEntityAllocationDialogOpen(true)}
        handleOpenSsidDialog={() => setSsidAllocationDialogOpen(true)}
      />
      <AllocateEntityDialog
        open={entityAllocationDialogOpen}
        onClose={handleClose}
        selectedRows={selectedRows}
        onUpdateFailure={onUpdateFailure}
        onUpdateSuccess={onUpdateSuccess}
      />
      <AllocateSSIDDialog
        onUpdateSuccess={onUpdateSuccess}
        onUpdateFailure={onUpdateFailure}
        onClose={handleClose}
        open={ssidAllocationDialogOpen}
        selectedRows={selectedRows}
      />
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
      >
        {snackbarSuccess === true ? (
          <Alert severity="success" elevation={6}>
            {snackbarMessages.map((message, i) => (
              <div key={i} id={i}>
                {message}
              </div>
            ))}
          </Alert>
        ) : (
          <Alert severity="error" elevation={6}>
            {snackbarMessages.length > 0 ? (
              snackbarMessages.map((error, i) => (
                <div key={i} id={i}>
                  {error}
                </div>
              ))
            ) : (
              <div>
                There was a problem trying to update the charge categorisations,
                please review your selection and try again.
              </div>
            )}
          </Alert>
        )}
      </Snackbar>
      <ApiProvider
        initialEndpoint={initialEndpoint}
        initialSortField={initialSort}
        searchFields={searchFields}
        lastUpdated={lastUpdated}
      >
        <ContextDataTable
          columns={columns}
          handleRowsSelected={handleRowsSelected}
          allowSearch
          props={props}
        />
      </ApiProvider>
    </React.Fragment>
  );
}

export default MiData;
