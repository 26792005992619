import javaApiInstance from "../../../api/javaApiInstance";

export function createMainSummaryRequest(values) {
  let data = {
    month: values.month,
    year: values.year
  };

  return javaApiInstance
    .post("reconciliation/start", data, {
      headers: {
        "Content-Type": "application/json"
      }
    })
    .then(({ res }) => res);
}

export function getReconciliationStatus() {
  return javaApiInstance.get("reconciliation/status").then(({ data }) => data);
}
