import React, { useState } from "react";
import { ApiProvider } from "../../japi";
import { ContextDataTable } from "../../shared/components/ContextDataTable";
import { downloadReport } from "./helpers/createAndClickDownloadLink";
import Link from "@material-ui/core/Link";
import CreateDialog from "./components/CreateDialog";
import FixedFab from "../../shared/components/FixedFab";
import UpdateIcon from "@material-ui/icons/Update";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "../../shared/components/Alert";
import { makeStyles } from "@material-ui/core";
import { generateRunMonthsArray } from "../../shared/helpers/getRunMonths";
import { generateRunYearsArray } from "../../shared/helpers/getRunYears";

const useStyles = makeStyles(theme => ({
  exetendedIcon: {
    marginRight: theme.spacing(0.5)
  }
}));

function ReportView() {
  //Styles
  const classes = useStyles();
  // modal state
  const [open, setOpen] = useState(false);
  const [searchFields] = useState(["reportType"]);

  function handleClickOpen() {
    setOpen(true);
  }

  function handleClose() {
    setOpen(false);
  }

  // snackbar state
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarSuccess, setSnackbarSuccess] = useState(false);
  const [snackbarMessages, setSnackbarMessages] = useState([]);

  const columns = [
    {
      name: "filename",
      label: "Filename",
      options: {
        filter: false,
        sort: true
      }
    },
    {
      name: "reportType",
      label: "Type",
      options: {
        filter: true,
        filterType: "multiselect",
        sort: true
      }
    },
    {
      name: "year",
      label: "Year",
      options: {
        filter: true,
        filterType: "multiselect",
        filterOptions: {
          names: generateRunYearsArray()
        },
        sort: false
      }
    },
    {
      name: "month",
      label: "Month",
      options: {
        filter: true,
        filterType: "multiselect",
        filterOptions: {
          names: generateRunMonthsArray()
        },
        sort: false
      }
    },
    {
      name: "createdDate",
      label: "Created",
      options: {
        filter: false,
        sort: true
      }
    },
    {
      name: "downloadLink",
      label: "Download",
      options: {
        filter: false,
        sort: false,
        customBodyRender: value => {
          if (value !== undefined) {
            return (
              <Link
                component="button"
                onClick={() => {
                  downloadReport({ value });
                }}
              >
                Download
              </Link>
            );
          } else {
            return "";
          }
        }
      }
    }
  ];
  return (
    <React.Fragment>
      <FixedFab onClick={handleClickOpen}>
        <UpdateIcon className={classes.exetendedIcon} />
        Request Report
      </FixedFab>
      <CreateDialog
        open={open}
        onClose={handleClose}
        onCreateFailure={errors => {
          setSnackbarOpen(true);
          setSnackbarSuccess(false);
          setSnackbarMessages(errors !== undefined ? errors : []);
        }}
        onCreateSuccess={() => {
          setSnackbarOpen(true);
          setSnackbarSuccess(true);
          setSnackbarMessages([]);
        }}
      />
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
      >
        {snackbarSuccess === true ? (
          <Alert severity="success" elevation={6}>
            Your report request has been received. Please check back in
            'Reports' in ~5 minutes.
          </Alert>
        ) : (
          <Alert severity="error" elevation={6}>
            {snackbarMessages.length > 0 ? (
              snackbarMessages.map((error, i) => (
                <div id={i}>{error["reason"]}</div>
              ))
            ) : (
              <div>
                There was a problem creating your report request. Please try
                again later.
              </div>
            )}
          </Alert>
        )}
      </Snackbar>
      <ApiProvider
        initialEndpoint={"report-files"}
        initialSortField={"createdAt"}
        searchFields={searchFields}
      >
        <ContextDataTable columns={columns} allowSearch />
      </ApiProvider>
    </React.Fragment>
  );
}

export default ReportView;
