import { createSignedUrlForGet } from "../api";

export function createAndClickDownloadLink(href) {
  const el = document.createElement("a");
  el.setAttribute("href", href);
  el.setAttribute("download", "");

  el.style.display = "none";
  document.body.appendChild(el);

  el.click();

  document.body.removeChild(el);
}

export async function downloadReport(filename) {
  try {
    const url = await createSignedUrlForGet(filename);
    createAndClickDownloadLink(url);
  } catch (e) {
    console.error(e);
  }
}
