import { call, put, takeLatest } from "redux-saga/effects";
import { fetchSchedules as fetchSchedulesApi } from "../api/index";
import * as actions from "../actions";

function* fetchSchedules({ date }) {
  try {
    const data = yield call(fetchSchedulesApi, date);
    yield put(actions.fetchSchedulesSuccess(data));
  } catch (e) {
    yield put({ type: actions.SCHEDULES_ERROR });
  }
}

export default [takeLatest(actions.SCHEDULES_REQUESTED, fetchSchedules)];
