import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { format, setMonth } from "date-fns";

import { useDispatch, useSelector } from "react-redux";
import { SUPPLIERS_REQUESTED } from "../../../shared/actions";
import { uploadFile } from "../api";
import truncateFilename from "../../../shared/helpers/truncateFilename";

import { makeStyles } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import UploadDropZone from "./UploadDropzone";
import throbber from "../../../assets/throbber.gif";

//@TODO - the form should be broken out of this component

const useStyles = makeStyles(theme => ({
  gridContainer: {
    paddingBottom: theme.spacing(1)
  },
  uploadButton: {
    color: theme.palette.success.contrastText,
    backgroundColor: theme.palette.success.main,
    "&:hover": {
      backgroundColor: theme.palette.success.dark
    }
  },
  progressContent: {
    minWidth: 600,
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(8)
  }
}));

function UploadDialog({
  onClose,
  onUploadFailure,
  onUploadSuccess,
  availableDates,
  open
}) {
  const classes = useStyles();

  const dispatch = useDispatch();
  const suppliers = useSelector(state => state.app.suppliers);

  // fetch a list of suppliers from the API each time that the dialog opens
  useEffect(() => {
    open && dispatch({ type: SUPPLIERS_REQUESTED });
  }, [dispatch, open]);

  const formattedDates = availableDates.map(date => ({
    year: format(date, "yyyy"),
    month: format(date, "MM")
  }));

  const years = formattedDates
    .map(date => date.year)
    .filter((a, i, b) => b.indexOf(a) === i);

  const getMonthsByYear = year =>
    formattedDates.filter(date => date.year === year).map(date => date.month);

  function monthHasCorrespondingYear(month) {
    let yearsWithMonth = [];
    formattedDates.forEach(date => {
      if (date.month === month) {
        if (!yearsWithMonth.includes(date.year)) {
          yearsWithMonth.push(date.year);
        }
      }
    });

    return !!yearsWithMonth.length;
  }

  // @TODO useReducer might make more sense here as we reuse the initial values
  const [selectedYear, setSelectedYear] = useState(formattedDates[0].year);
  const [months, setMonths] = useState(getMonthsByYear(selectedYear));
  const [selectedMonth, setSelectedMonth] = useState(formattedDates[0].month);
  const [selectedSupplier, setSelectedSupplier] = useState("none");
  const [selectedFiles, setSelectedFiles] = useState(null);
  const [isUploading, setIsUploading] = useState(false);

  function setInitialState() {
    setSelectedYear(formattedDates[0].year);
    setSelectedMonth(formattedDates[0].month);
    setSelectedSupplier("none");
    setSelectedFiles(null);
    setIsUploading(false);
  }

  function isValid() {
    //@TODO - should be able to use "" as value for placeholder (see mui docs)
    // but this implementation doesn't seem to display placeholder in that case
    return (
      selectedYear !== "none" &&
      selectedMonth !== "none" &&
      selectedSupplier !== "none" &&
      selectedFiles
    );
  }

  function handleSelectYear(e) {
    const year = e.target.value;

    if (!getMonthsByYear(year).includes(selectedMonth)) {
      setSelectedMonth("none");
    }

    setSelectedYear(year);
    setMonths(getMonthsByYear(year));
  }

  function handleSelectMonth(e) {
    const month = e.target.value;

    if (!monthHasCorrespondingYear(month)) {
      setSelectedYear("none");
    }
    setSelectedMonth(month);
  }

  function handleExited() {
    setInitialState();
  }

  function handleClose() {
    onClose();
  }

  async function handleUpload() {
    setIsUploading(true);
    try {
      await uploadFile(
        selectedYear,
        selectedMonth,
        selectedFiles[0],
        selectedSupplier
      );
      onUploadSuccess();
    } catch (e) {
      console.error(e);
      onUploadFailure();
    } finally {
      onClose();
    }
  }

  return (
    <Dialog
      open={open}
      onExited={handleExited}
      onClose={onClose}
      aria-labelledby="supplier-files-upload-form"
      disableBackdropClick={isUploading}
      disableEscapeKeyDown={isUploading}
    >
      {isUploading ? (
        <DialogContent className={classes.progressContent}>
          <Box
            color="success.main"
            mb={4}
            display="flex"
            justifyContent="center"
          >
            <img src={throbber} alt={"A spinner"} />
          </Box>
          <DialogContentText align="center" variant="h5" component="div">
            Uploading{" "}
            {selectedFiles ? truncateFilename(selectedFiles[0].name) : "file"}
          </DialogContentText>
        </DialogContent>
      ) : (
        <React.Fragment>
          <DialogTitle id="supplier-files-upload-form" disableTypography>
            <Box component="h2" fontSize="1.5rem" fontWeight="400" m={0}>
              Upload Supplier File
            </Box>
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              Please select the supplier, year and month that the supplier file
              data relates to, and drag a file into the box below.
            </DialogContentText>
            <DialogContentText>
              Please Note: the upload may take several seconds for a large file.
            </DialogContentText>
            <Box pt={1}>
              <Grid
                container
                spacing={2}
                classes={{ container: classes.gridContainer }}
              >
                <Grid item xs={6}>
                  <FormControl
                    size="small"
                    variant="outlined"
                    fullWidth
                    disabled={suppliers.length <= 0}
                  >
                    <Select
                      value={selectedSupplier}
                      onChange={e => setSelectedSupplier(e.target.value)}
                    >
                      {selectedSupplier === "none" && (
                        <MenuItem disabled value="none">
                          {suppliers.length > 0
                            ? "Select supplier"
                            : "Fetching suppliers..."}
                        </MenuItem>
                      )}
                      {suppliers.map(({ name, code }, i) => (
                        <MenuItem key={i} value={code}>
                          {name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
              <Grid
                container
                spacing={2}
                classes={{ container: classes.gridContainer }}
              >
                <Grid item xs={6}>
                  <FormControl size="small" variant="outlined" fullWidth>
                    <Select value={selectedYear} onChange={handleSelectYear}>
                      {selectedYear === "none" && (
                        <MenuItem disabled value="none">
                          Select year
                        </MenuItem>
                      )}
                      {years.map((year, i) => (
                        <MenuItem key={i} value={year}>
                          {year}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <FormControl size="small" variant="outlined" fullWidth>
                    <Select value={selectedMonth} onChange={handleSelectMonth}>
                      {selectedMonth === "none" && (
                        <MenuItem disabled value="none">
                          Select month
                        </MenuItem>
                      )}
                      {months.map((month, i) => (
                        <MenuItem key={i} value={month}>
                          {format(
                            setMonth(new Date(), Number(month - 1)),
                            "MMMM"
                          )}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
              <Box mt={1}>
                <UploadDropZone onDropAccepted={setSelectedFiles} />
              </Box>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button
              className={classes.uploadButton}
              size="large"
              variant="contained"
              disabled={!isValid()}
              onClick={handleUpload}
            >
              Upload
            </Button>
            <Button onClick={handleClose} size="large" variant="text">
              Cancel
            </Button>
          </DialogActions>
        </React.Fragment>
      )}
    </Dialog>
  );
}

UploadDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  availableDates: PropTypes.arrayOf(PropTypes.instanceOf(Date)),
  onUploadSuccess: PropTypes.func.isRequired,
  onUploadFailure: PropTypes.func.isRequired
};

export default UploadDialog;
