import React from "react";
import PropTypes from "prop-types";
import { useDropzone } from "react-dropzone";
import { makeStyles } from "@material-ui/core";

import Box from "@material-ui/core/Box";
import InsertDriveFileOutlinedIcon from "@material-ui/icons/InsertDriveFileOutlined";
import truncateFilename from "../../../shared/helpers/truncateFilename";

const useStyles = makeStyles(theme => ({
  root: {
    borderWidth: 2,
    borderColor: theme.palette.success.main,
    borderStyle: "dashed",
    borderRadius: theme.shape.borderRadius,
    height: "180px",
    outline: "none"
  },
  icon: {
    color: theme.palette.grey[400]
  },
  button: {
    marginRight: theme.spacing(1)
  }
}));

function UploadDropZone({ onDropAccepted }) {
  const { acceptedFiles, getRootProps, open, getInputProps } = useDropzone({
    noClick: true,
    noKeyboard: true,
    multiple: false,
    onDropAccepted
  });

  const classes = useStyles();

  const filenameForDisplay = acceptedFiles.map((file, i) => (
    <span key={i}>{truncateFilename(file.name)}</span>
  ))[0];

  return (
    <section>
      <div {...getRootProps({ className: classes.root })}>
        <input {...getInputProps()} />
        <Box
          display="flex"
          justifyContent="center"
          height="100%"
          alignItems="center"
          flexDirection="column"
        >
          <InsertDriveFileOutlinedIcon
            fontSize="large"
            className={classes.icon}
          />
          <Box fontWeight="400" fontSize="1rem" py={1}>
            Drag CSV Supplier file here to upload
          </Box>
          <Box display="flex" alignItems="baseline">
            <button type="button" onClick={open} className={classes.button}>
              Choose file
            </button>{" "}
            {filenameForDisplay}
          </Box>
        </Box>
      </div>
    </section>
  );
}

UploadDropZone.propTypes = {
  onDropAccepted: PropTypes.func.isRequired
};

export default UploadDropZone;
