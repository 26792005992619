import React, { useState } from "react";
import PropTypes from "prop-types";

import { makeStyles } from "@material-ui/core";
import {
  generateRunMonthsArray,
  formatMonth
} from "../../../shared/helpers/getRunMonths";
import { generateRunYearsArray } from "../../../shared/helpers/getRunYears";

import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { createMainSummaryRequest } from "../api";

const useStyles = makeStyles(theme => ({
  gridContainer: {
    paddingBottom: theme.spacing(1)
  },
  submitButton: {
    color: theme.palette.success.contrastText,
    backgroundColor: theme.palette.success.main,
    "&:hover": {
      backgroundColor: theme.palette.success.dark
    }
  }
}));

function RequestSummaryDialog({
  open,
  onClose,
  onCreateFailure,
  onCreateSuccess
}) {
  const [year, setYear] = useState(new Date().getFullYear());
  const [month, setMonth] = useState(formatMonth(new Date().getMonth() + 1));

  function handleClose() {
    onClose();
  }

  async function handleSubmit() {
    try {
      let values = {
        month: month,
        year: year
      };
      await createMainSummaryRequest(values);
      onCreateSuccess();
    } catch (e) {
      onCreateFailure();
      console.error(e);
    }
    handleClose();
  }

  const classes = useStyles();

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="create-schedule-form"
    >
      <DialogTitle id="supplier-files-upload-form" disableTypography>
        <Box component="h2" fontSize="1.5rem" fontWeight="400" m={0}>
          Request Main Summary Update
        </Box>
      </DialogTitle>
      <form>
        <DialogContent>
          <Box pt={1}>
            <Grid
              container
              spacing={2}
              classes={{ container: classes.gridContainer }}
            >
              <Grid item xs={6}>
                <FormControl
                  fullWidth
                  label={"Run Year"}
                  size="small"
                  variant="outlined"
                >
                  <Select
                    name="year"
                    value={year}
                    onChange={e => setYear(e.target.value)}
                  >
                    <MenuItem value={""} disabled>
                      Select year
                    </MenuItem>
                    {generateRunYearsArray().map((year, i) => (
                      <MenuItem key={i} value={year}>
                        {year}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl fullWidth size="small" variant="outlined">
                  <Select
                    name="month"
                    value={month}
                    onChange={e => setMonth(e.target.value)}
                  >
                    <MenuItem value={""} disabled>
                      Select month
                    </MenuItem>
                    {generateRunMonthsArray().map((month, i) => (
                      <MenuItem key={i} value={month}>
                        {month}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            className={classes.submitButton}
            size="large"
            variant="contained"
            onClick={handleSubmit}
          >
            Submit
          </Button>
          <Button onClick={handleClose} size="large" variant="text">
            Cancel
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

RequestSummaryDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onCreateFailure: PropTypes.func.isRequired,
  onCreateSuccess: PropTypes.func.isRequired
};

export default RequestSummaryDialog;
