import { closestTo, formatDistanceToNow } from "date-fns";

export default function daysToClosestDate(datesArray) {
  let days;

  try {
    const mostRecent = closestTo(new Date(), datesArray);
    days = formatDistanceToNow(mostRecent);
  } catch (e) {
    console.error(e);
  }

  return days;
}
