import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import {
  CircularProgress,
  InputLabel,
  makeStyles,
  MenuItem,
  Select
} from "@material-ui/core";

import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";

import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import { useAuth0 } from "../../../../react-auth0-spa";
import { fetchBrandToEntity } from "../../../MiData/api";
import { createEntity, updateEntity } from "../../api";
import { API_PLATFORM_BASE_URL } from "../../../../config";

const useStyles = makeStyles(theme => ({
  gridContainer: {
    paddingBottom: theme.spacing(1)
  },
  submitButton: {
    color: theme.palette.success.contrastText,
    backgroundColor: theme.palette.success.main,
    "&:hover": {
      backgroundColor: theme.palette.success.dark
    }
  },
  submitButtonEdit: {
    color: theme.palette.warning.contrastText,
    backgroundColor: theme.palette.warning.main,
    "&:hover": {
      backgroundColor: theme.palette.warning.dark
    }
  }
}));

function ManualAllocationDialog({
  open,
  onClose,
  updateSnackbar,
  onSuccess,
  edit,
  rowData,
  id
}) {
  const { user } = useAuth0();

  const [account, setAccount] = useState(null);
  const [brandToEntity, setBrandToEntity] = useState("");
  const [ssid, setSsid] = useState(null);
  const [options, setOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [fieldStatuses, setFiledStatuses] = useState(initFieldStatues());

  useEffect(() => {
    if (open) {
      setIsLoading(true);
      fetchBrandToEntity().then(data => {
        setOptions(data);
        setIsLoading(false);
      });
    }
  }, [open]);

  useEffect(() => {
    function initForm() {
      if (edit) {
        setSsid(rowData[0]);
        setAccount(rowData[3]);
        setBrandToEntity(rowData[7] ? rowData[7] : "");
      }
    }

    clearData();
    initForm();
  }, [open, rowData, edit]);

  //Validate form input
  function isValid() {
    return ssid !== null && brandToEntity !== "" && account !== null;
  }

  const clearData = () => {
    setAccount(null);
    setBrandToEntity(null);
    setSsid(null);
  };

  function handleClose() {
    onClose();
  }

  const onDialogClose = () => {
    clearData();
    handleClose();
  };

  function initFieldStatues() {
    const initState = {
      error: false,
      errorHelperText: ""
    };

    return {
      ssid: initState,
      brandToEntity: initState,
      resellerAcct: initState
    };
  }

  function generateOptions() {
    return options.map(brandToEntity => {
      return (
        <MenuItem
          value={brandToEntity}
          id={brandToEntity.id}
          key={brandToEntity.id}
        >
          {brandToEntity.brand}
        </MenuItem>
      );
    });
  }

  const updateFormErrors = errors => {
    let updatedFieldStatuses = initFieldStatues();

    errors.forEach(error => {
      let fieldName = error.property ? error.property : error.field;
      let message = error.message ? error.message : error.defaultMessage;

      updatedFieldStatuses[fieldName] = {
        error: true,
        errorHelperText: message
      };
    });

    setFiledStatuses(updatedFieldStatuses);
  };

  async function handleSubmit() {
    let manualAllocation = {
      ssid: ssid,
      brandToEntity: `${API_PLATFORM_BASE_URL}/brand-to-entity/${brandToEntity.id}`,
      resellerAcct: account,
      username: user.name,
      dateAdded: new Date().toISOString()
    };

    let response;

    if (edit) {
      response = await updateEntity(
        id,
        "manual-entity-allocation",
        manualAllocation
      );
    } else {
      response = await createEntity(
        "manual-entity-allocation",
        manualAllocation
      );
    }

    if (response.success) {
      updateSnackbar(true, response.message);
      onSuccess();
      onDialogClose();
    } else if (response.errors.length > 0) {
      updateFormErrors(response.errors);
    } else {
      updateSnackbar(false, response.message);
      onDialogClose();
    }

    handleClose();
  }

  const classes = useStyles();

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="create-schedule-form"
    >
      <DialogTitle>
        {edit ? "Edit" : "Create"} manual entity allocation
      </DialogTitle>
      <form>
        <DialogContent>
          <Box pt={1}>
            <Grid
              container
              spacing={2}
              classes={{ container: classes.gridContainer }}
            >
              <Grid item xs={12}>
                <FormControl fullWidth label={"SSID"} variant="outlined">
                  <TextField
                    required
                    disabled={edit}
                    label={"SSID"}
                    name="ssid"
                    onChange={e => setSsid(e.target.value)}
                    error={fieldStatuses.ssid.error}
                    helperText={fieldStatuses.ssid.errorHelperText}
                    value={ssid}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12}>
                <FormControl fullWidth label={"Brand *"} variant="outlined">
                  <InputLabel>Brand</InputLabel>
                  <Select
                    required
                    label="Brand *"
                    name="brand"
                    onChange={e => setBrandToEntity(e.target.value)}
                    error={fieldStatuses.brandToEntity.error}
                    helperText={fieldStatuses.brandToEntity.errorHelperText}
                    value={brandToEntity}
                    renderValue={selected => selected.brand}
                  >
                    {isLoading ? (
                      <CircularProgress size={20} />
                    ) : (
                      generateOptions()
                    )}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12}>
                <FormControl fullWidth label={"Entity"} variant="outlined">
                  <TextField
                    required
                    disabled
                    label={"Entity"}
                    name="entity"
                    value={brandToEntity ? brandToEntity.entity : ""}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12}>
                <FormControl
                  fullWidth
                  label={"Reseller Account"}
                  variant="outlined"
                >
                  <TextField
                    required
                    label={"Reseller Account"}
                    name="resellerAccount"
                    error={fieldStatuses.resellerAcct.error}
                    helperText={fieldStatuses.resellerAcct.errorHelperText}
                    onChange={e => setAccount(e.target.value)}
                    value={account}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            className={edit ? classes.submitButtonEdit : classes.submitButton}
            size="large"
            variant="contained"
            onClick={handleSubmit}
            disabled={!isValid()}
          >
            Submit
          </Button>
          <Button onClick={handleClose} size="large" variant="text">
            Cancel
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

ManualAllocationDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired
};

export default ManualAllocationDialog;
