import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { SCHEDULES_REQUESTED } from "./actions";
import { makeStyles, Box } from "@material-ui/core";
import { parseISO } from "date-fns";
import daysToClosestDate from "../../shared/helpers/daysToClosestDate";

import CreateDialog from "./components/CreateDialog";
import DataTable from "../../shared/components/DataTable";
import FixedFab from "../../shared/components/FixedFab";
import UpdateIcon from "@material-ui/icons/Update";
import Statuses from "../../shared/components/Statuses";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "../../shared/components/Alert";

const useStyles = makeStyles(theme => ({
  exetendedIcon: {
    marginRight: theme.spacing(0.5)
  }
}));

function Schedules() {
  const classes = useStyles();

  //redux bindings
  const dispatch = useDispatch();
  const { error, loading, data } = useSelector(state => state.schedules);

  useEffect(() => {
    dispatch({ type: SCHEDULES_REQUESTED });
  }, [dispatch]);

  // modal state
  const [open, setOpen] = useState(false);
  function handleClickOpen() {
    setOpen(true);
  }

  function handleClose() {
    setOpen(false);
  }

  // snackbar state
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  return (
    <Statuses loading={loading} error={error} noData={!data.length}>
      <Box
        my={5}
        fontSize="1.5rem"
        display="flex"
        justifyContent="space-between"
      >
        <div>
          Next schedule due to run in{" "}
          {data.length > 0 &&
            daysToClosestDate(data.map(x => parseISO(x.runDate)))}
        </div>
        <div>{data.length} Schedules</div>
      </Box>
      <DataTable
        dateTimeSelectors={["runDate"]}
        defaultSortSelector="runDate"
        title="Schedules"
        data={data.map(row => ({
          id: row.id,
          scheduleName: row.scheduleName,
          runDate: row.runDate,
          host: row.host,
          supplier: row.supplier,
          edit: <a href="/">Edit</a>
        }))}
        columns={[
          { title: "Schedule Name", selector: "scheduleName" },
          { title: "Supplier", selector: "supplier" },
          { title: "Host", selector: "host" },
          { title: "Run Date", selector: "runDate" }
          // { title: "Action", selector: "edit" }
        ]}
      />
      <FixedFab onClick={handleClickOpen}>
        <UpdateIcon className={classes.exetendedIcon} />
        Create Schedule
      </FixedFab>
      <CreateDialog
        open={open}
        onClose={handleClose}
        onCreateFailure={() => setSnackbarOpen(true)}
      />
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
      >
        <Alert severity="error" elevation={6}>
          There was a problem creating your schedule. Please try again later.
        </Alert>
      </Snackbar>
    </Statuses>
  );
}

export default Schedules;
