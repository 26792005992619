import {
  RECONCILIATION_STATUS_ERROR,
  RECONCILIATION_STATUS_REQUESTED,
  RECONCILIATION_STATUS_SUCCESS
} from "../actions";

const initialState = {
  loading: true,
  error: false,
  steps: [],
  overallStatus: undefined,
  averageTime: undefined,
  runningTime: undefined,
  reconDate: undefined
};

export default function(state = initialState, action) {
  switch (action.type) {
    case RECONCILIATION_STATUS_REQUESTED: {
      return { ...state };
    }
    case RECONCILIATION_STATUS_SUCCESS:
      return {
        ...state,
        steps: action.payload.steps,
        overallStatus: action.payload.overallStatus,
        averageTime: action.payload.averageTime,
        runningTime: action.payload.runningTime,
        reconDate: action.payload.reconDate,
        loading: false,
        error: false
      };
    case RECONCILIATION_STATUS_ERROR:
      return { ...state, loading: false, error: true };
    default:
      return state;
  }
}
