import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core";

import Checkbox from "@material-ui/core/Checkbox";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";

import { format, parseISO, compareDesc } from "date-fns";

const useMountEffect = fun => useEffect(fun, []);
const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: "rgba(0,0,0,0.04)",
    "&$selected": {
      backgroundColor: "#fff"
    }
  },
  selected: {}
}));

function DataTable({
  dateTimeSelectors = [],
  defaultSortSelector,
  colorReverse = false,
  data,
  selectedRowIds = [],
  onRowSelect,
  selectAllOnMount = false,
  columns,
  rowsPerPage = 30,
  title = ""
}) {
  const classes = useStyles();

  const [page, setPage] = useState(0);
  function handleChangePage(event, newPage) {
    setPage(newPage);
  }

  const selectAll = () => onRowSelect(data.map(el => el.id));
  useMountEffect(() => {
    selectAllOnMount && selectAll();
  });

  function handleSelectAll(event) {
    if (event.target.checked) {
      return selectAll();
    }
    onRowSelect([]);
  }

  function handleCheckClick(id) {
    let newSelected = [];
    // if already selected remove it from the list
    if (selectedRowIds.includes(id)) {
      newSelected = selectedRowIds.filter(el => el !== id);
    }
    // if not already selected add it to the list
    if (!selectedRowIds.includes(id)) {
      newSelected = selectedRowIds.concat(id);
    }
    onRowSelect(newSelected);
  }

  const selectors = columns.map(column => column.selector);

  // sorting
  const order = "desc";
  const orderBy = defaultSortSelector;

  function descendingComparator(a, b, orderBy) {
    if (!orderBy) {
      return 0;
    }
    if (dateTimeSelectors.includes(orderBy)) {
      return compareDesc(parseISO(a[orderBy]), parseISO(b[orderBy]));
    } else {
      if (b[orderBy] < a[orderBy]) {
        return -1;
      }
      if (b[orderBy] > a[orderBy]) {
        return 1;
      }
      return 0;
    }
  }

  function getComparator(order, orderBy) {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
  }

  return (
    <TableContainer>
      <Table size="small" aria-label={title}>
        <TableHead>
          <TableRow>
            {onRowSelect && (
              <TableCell padding="checkbox">
                <Checkbox
                  size="small"
                  onChange={handleSelectAll}
                  checked={data.length === selectedRowIds.length}
                  indeterminate={
                    selectedRowIds.length > 0 &&
                    selectedRowIds.length < data.length
                  }
                />
              </TableCell>
            )}

            {columns.map((column, i) => (
              <TableCell key={i}>{column.title}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {stableSort(data, getComparator(order, orderBy))
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map(row => (
              <TableRow
                onClick={onRowSelect && (() => handleCheckClick(row.id))}
                hover
                key={row.id}
                selected={selectedRowIds.includes(row.id)}
                classes={
                  colorReverse
                    ? {
                        root: classes.root,
                        selected: classes.selected
                      }
                    : {}
                }
              >
                {onRowSelect && (
                  <TableCell padding="checkbox">
                    <Checkbox
                      checked={selectedRowIds.includes(row.id)}
                      size="small"
                    />
                  </TableCell>
                )}

                {selectors.map((selector, i) => {
                  let val = row[selector];
                  if (dateTimeSelectors.includes(selector)) {
                    val = format(parseISO(row[selector]), "dd/MM/yyyy kk:mm");
                  }
                  return <TableCell key={i}>{val}</TableCell>;
                })}
              </TableRow>
            ))}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[rowsPerPage]}
        component="div"
        count={data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
      />
    </TableContainer>
  );
}

DataTable.propTypes = {
  dateTimeSelectors: PropTypes.arrayOf(PropTypes.string.isRequired),
  defaultSortSelector: PropTypes.string,
  colorReverse: PropTypes.bool,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired
    }).isRequired
  ).isRequired,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      selector: PropTypes.string.isRequired
    })
  ),
  selectedRowIds: PropTypes.arrayOf(PropTypes.string.isRequired),
  onRowSelect: PropTypes.func,
  selectAllOnMount: PropTypes.bool,
  rowsPerPage: PropTypes.number,
  title: PropTypes.string.isRequired
};

export default DataTable;
