import javaApiInstance from "../../../api/javaApiInstance";

//PATCH of identifiers & charge reason/detail update combinations
export async function updateChargeInfo(list) {
  let responseList = [];

  for (let i = 0; i < list.length; i++) {
    try {
      await javaApiInstance
        .patch(`supplier-row-data/${list[i].id}`, list[i])
        .then(
          response => {
            if (response.status === 204 || response.status === 200) {
              responseList.push([list[i].id, "true"]);
            } else {
              responseList.push([list[i].id, "false"]);
            }
          },
          () => {
            responseList.push([list[i].id, "false"]);
          }
        );
    } catch (e) {
      responseList.push([list[i].id, "false"]);
    }
  }

  return responseList;
}

//GET charge info via Java REST HATEOAS API from production db charge_info_mapping table
export function fetchChargeInfo() {
  return javaApiInstance
    .request(`charge-info?size=1000`)
    .then(({ data }) => data);
}
